



































import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import Confirmation from '@/components/modals/Confirmation.vue'
import FilesList from '@/components/FilesList.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import TextAreaInput from '@/components/_uikit/controls/TextAreaInput.vue'
import UploadInput from '@/components/_uikit/controls/UploadInput.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import { MediaResource, TicketShortResource, TicketStore } from '@/store/types'
import SupportModule from '@/store/modules/support'
import AttachmentsWrapper from '@/components/_uikit/AttachmentsWrapper.vue'

@Component({
  components: {
    AttachmentsWrapper,
    Confirmation,
    FilesList,
    TextAreaInput,
    TextInput,
    UploadInput,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class TicketForm extends Mixins(NotifyMixin) {
  private form: TicketStore = {
    mediaIds: [],
    message: '',
    theme: '',
  }
  private files: MediaResource[] = []

  private handleUploadFile (response: MediaResource) {
    this.files.push(response)
    this.form.mediaIds.push(response.id)
  }

  private handleDeleteFile (id: number) {
    this.files = this.files.filter((file: MediaResource) => file.id !== id)
    this.form.mediaIds = this.form.mediaIds.filter((item: number) => item !== id)
  }

  @Debounce(500)
  @Bind
  private handleSendMessage () {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          SupportModule.createTicket(this.form)
            .then((response: TicketShortResource) => {
              this.notifySuccess('Ваше обращение создано. В ближайшее время мы ответим на ваш вопрос')
              this.files = []
              requestAnimationFrame(() => (form.reset()))
              this.$emit('success', response)
            })
            .catch(this.notifyError)
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }
}
