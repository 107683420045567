import { AxiosResponse, CancelToken, Method } from 'axios'

import api from '@/utils/services/api'

type QueryParams = { [key: string]: any }

export interface RequestArgs extends RequestInit {
  cancelToken?: CancelToken,
  loading?: boolean,
  masterId?: number,
  params?: QueryParams | null,
  timeout?: number,
}

export async function request<T> (url: string, rest: RequestArgs): Promise<AxiosResponse<T>> {
  return api({
    cancelToken: rest.cancelToken,
    data: rest.body,
    headers: rest.headers,
    method: (rest.method as Method),
    params: {
      ...rest.params,
      loading: rest.loading,
      masterId: rest.masterId,
    },
    timeout: rest.timeout,
    url,
  })
}

export async function requestGamification<T> (url: string, rest: RequestArgs): Promise<AxiosResponse<T>> {
  return api({
    data: rest.body,
    headers: rest.headers,
    method: (rest.method as Method),
    params: {
      ...rest.params,
      loading: rest.loading,
    },
    url: '/gamification' + url ,
  })
}
