




import { Component, Vue } from 'vue-property-decorator'

// utils
import { isNewYear } from '@/utils/constants'

@Component
export default class NewYearHat extends Vue {
  private get isNewYear() {
    return isNewYear
  }
}
