import { v4 as uuid } from 'uuid'
import { Component, Mixins, Ref, Watch } from 'vue-property-decorator'
import { orderBy } from 'lodash'

import {
  GET_DEFAULT_CODING_QUESTION_FORM,
  GET_DEFAULT_PRACTICE_QUESTION_FORM,
  GET_DEFAULT_SPEAKING_QUESTION_FORM,
  GET_DEFAULT_TASK_FORM,
  GET_DEFAULT_TEST_QUESTION_FORM,
} from '@/components/forms/exercise/constants'
import Confirmation from '@/components/modals/Confirmation.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import DictionaryModule from '@/store/modules/dictionary'
import ManagerCoursesModule from '@/store/modules/manager/courses'
import ManagerExercisesModule from '@/store/modules/manager/exercises'
import MentorExercisesModule from '@/store/modules/mentor/exercises'
import {
  CodingQuestionResource,
  ExerciseLargeResource,
  MediaResource,
  NameValueResource,
  PracticeQuestionResource,
  SpeakingQuestionResource, TaskCodingResource,
  TaskCreativeResource,
  TaskPracticeResource,
  TaskQuestionType,
  TaskRateType,
  TaskSpeakingResource,
  TaskStoreRequest,
  TaskTestResource,
  TaskType,
  TestQuestionResource,
} from '@/store/types'
import { clone } from '@/utils/functions'

enum MenuActions {
  ADD_NEW_TASK = 'addNewTask',
  // ADD_TEMPLATE_TASK = 'addTemplateTask',
  COPY_TASK = 'copyTask',
  DELETE_TASK = 'deleteTask',
}

@Component
export default class ExerciseEditMixin extends Mixins(NotifyMixin) {
  @Ref() confirm!: Confirmation

  protected tasks: TaskStoreRequest[] = []
  protected isProbe = false
  protected selectedTaskIndex = 0
  protected formMaterials: MediaResource[] = []
  protected subQuestionsRecords: MediaResource[] = []

  protected get exercise(): ExerciseLargeResource | null {
    return ManagerExercisesModule.exercise
  }

  protected get exerciseCourse(): ExerciseLargeResource | null {
    return ManagerCoursesModule.exercise
  }

  protected get exerciseMentor(): ExerciseLargeResource | null {
    return MentorExercisesModule.exercise
  }

  protected get taskTypes () {
    return DictionaryModule.taskTypes
  }

  protected get tabs () {
    return this.tasks.map((task: TaskStoreRequest, index: number) => `${index + 1}. ${this.taskTypes.find((type: NameValueResource) => type.value === task.type)?.name || 'Неизвестный тип задания'}`)
  }

  protected get menu () {
    return [
      { name: 'Добавить новое задание', value: MenuActions.ADD_NEW_TASK },
      // { name: 'Добавить задание из шаблона', value: MenuActions.ADD_TEMPLATE_TASK },
      { name: 'Дублировать', value: MenuActions.COPY_TASK },
      { name: 'Удалить', value: MenuActions.DELETE_TASK },
    ]
  }

  protected addMaterials(files: MediaResource[]) {
    this.formMaterials.push(...files)
  }

  protected syncTasks(object: 'exercise' | 'exerciseCourse' | 'exerciseMentor') {
    const exercise = this[object]
    if (exercise) {
      this.tasks = exercise.tasks.map((task: TaskCreativeResource|TaskPracticeResource|TaskTestResource|TaskSpeakingResource|TaskCodingResource) => ({
        annotation: task.annotation,
        answerMediaIds: task.answerMedia ? task.answerMedia.map((file: MediaResource) => {
          this.formMaterials.push(file)
          return file.id
        }) : [],
        coding: {
          questions: task.type.value === TaskType.CODING && (task as TaskCodingResource).questions
            ? (task as TaskCodingResource).questions.map((question: CodingQuestionResource) => {
              if (question.media) {
                this.formMaterials.push(...question.media)
              }
              return {
                displayedPosition: question.displayedPosition,
                hintAnswer: question.hintAnswer ?? '',
                hintQuestion: question.hintQuestion ?? '',
                id: question.id,
                maxPoints: question.maxPoints,
                mediaIds: question.media.map((file: MediaResource) => file.id),
                position: question.position,
                question: question.question,
                uuid: question.uuid,
              }
            })
            : [GET_DEFAULT_CODING_QUESTION_FORM()],
        },
        description: task.description,
        exerciseUuid: task.exerciseUuid,
        managerAnnotation: task.managerAnnotation,
        masterAnnotation: task.masterAnnotation,
        maxPoints: task.maxPoints,
        mediaIds: task.media.map((file: MediaResource) => {
          this.formMaterials.push(file)
          return file.id
        }),
        practice: {
          questions: task.type.value === TaskType.PRACTICE && (task as TaskPracticeResource).questions
            ? (task as TaskPracticeResource).questions.map((question: PracticeQuestionResource) => {
              if (question.media) {
                this.formMaterials.push(...question.media)
              }
              return {
                answerId: question.answerId,
                displayedPosition: question.displayedPosition || '20',
                hintQuestion: question.hintQuestion || '',
                id: question.id,
                maxPoints: question.maxPoints,
                mediaIds: question.media.map((file: MediaResource) => file.id),
                points: question.points,
                position: question.position,
                question: question.question,
                rightAnswer: question.rightAnswer,
                uuid: question.uuid,
              }
            })
            : [GET_DEFAULT_PRACTICE_QUESTION_FORM()],
        },
        speaking: {
          questions: task.type.value === TaskType.SPEAKING && (task as TaskSpeakingResource).questions
            ? (task as TaskSpeakingResource).questions.map((question: SpeakingQuestionResource) => {
              if (question.media) {
                this.formMaterials.push(...question.media)
              }
              if (question.type === 'audio_queue' && question.questions && question.questions.length) {
                question.questions.map(subQustions => {
                  if (subQustions.media)
                    this.subQuestionsRecords.push(subQustions.media)
                })
              }
              return {
                id: question.id,
                mediaIds: question.media ? question.media.map((file: MediaResource) => file.id) : [],
                position: question.position,
                question: question.question,
                questions: question.questions ? question.questions.map(subQuestion => {
                  return {
                    id: subQuestion.id,
                    mediaId: subQuestion.media ? subQuestion.media.id : undefined,
                    position: subQuestion.position,
                    questionId: subQuestion.questionId,
                    required: subQuestion.required,
                    text: subQuestion.text || undefined,
                  }
                }) : [],
                text: question.text || undefined,
                type: question.type,
              }
            })
            : [GET_DEFAULT_SPEAKING_QUESTION_FORM()],
        },
        test: {
          questions: task.type.value === TaskType.TEST && (task as TaskTestResource).questions
            ? orderBy((task as TaskTestResource).questions.map((question: TestQuestionResource) => {
              if (question.media) {
                this.formMaterials.push(...question.media)
              }
              return {
                answers: question.type.value === TaskQuestionType.TEXT ? [...question.answers, {
                  isCorrect: false,
                  position: question.answers.length + 1,
                  text: '',
                }] : question.answers,
                displayType: question.displayType || 'column',
                hintAnswer: question.hintAnswer || '',
                hintQuestion: question.hintQuestion || '',
                id: question.id,
                manyErrorPoints: question.manyErrorPoints,
                mediaIds: question.media.map((file: MediaResource) => file.id),
                nameAnswerTable: question.nameAnswerTable || '',
                nameSequenceTable: question.nameSequenceTable || '',
                oneErrorPoints: question.oneErrorPoints,
                points: question.points,
                position: question.position || 1,
                question: question.question,
                rateType: question.rateType.value as TaskRateType,
                relatedUuid: question.relatedUuid,
                sequences: question.sequences || [],
                type: question.type.value as TaskQuestionType,
                uuid: question.uuid,
              }
            }), 'position')
            : [GET_DEFAULT_TEST_QUESTION_FORM()],
        },
        title: task.title,
        type: task.type.value as TaskType,
        uuid: task.uuid,
        verificationHours: task.verificationHours,
      })) as TaskStoreRequest[]
    }
  }

  protected deleteCurrentTask(object: 'exercise' | 'exerciseCourse' | 'exerciseMentor') {
    if (!this.tasks.length) {
      this.handleAddTask(object)
    } else {
      this.tasks.splice(this.selectedTaskIndex, 1)
    }
    this.selectedTaskIndex = 0
    this.notifySuccess('Задание удалено')
  }

  protected handleAddTask(object: 'exercise' | 'exerciseCourse' | 'exerciseMentor') {
    const exercise = this[object]
    if (exercise) {
      this.tasks.push({
        ...GET_DEFAULT_TASK_FORM(),
        exerciseUuid: exercise.uuid,
        uuid: uuid(),
      })
      this.selectedTaskIndex = this.tasks.length - 1
    }
  }

  // protected handleAddTemplateTask () {
  //   this.notifyError('Делаем в ПРП')
  // }

  protected confirmCopyTask () {
    this.confirm.open(
      'Копирование задания',
      'Вы действительно хотите скопировать задание?',
      {
        buttonConfirmText: 'Копировать',
        skin: 'secondary',
      },
    )
      .then(this.handleCopyTask)
      .catch(() => {return})
  }

  protected handleCopyTask () {
    const relatedMap = this.tasks[this.selectedTaskIndex].test.questions.reduce((carry: { [key: string]: string }, item) => {
      if (item.relatedUuid && !carry[item.relatedUuid]) {
        carry[item.relatedUuid] = uuid()
      }
      return carry
    }, {})

    const cloneTask = {
      ...clone(this.tasks[this.selectedTaskIndex]),
      coding: {
        questions: this.tasks[this.selectedTaskIndex].coding.questions.map(q=> ({
          ...clone(q),
          uuid: uuid(),
        })),
      },
      practice: {
        questions: this.tasks[this.selectedTaskIndex].practice.questions.map(q => ({
          ...clone(q),
        })),
      },
      speaking: {
        questions: this.tasks[this.selectedTaskIndex].speaking.questions.map(q => ({
          ...clone(q),
          questions: q.questions.map(subQ => clone(subQ)),
        })),
      },
      test: {
        questions: this.tasks[this.selectedTaskIndex].test.questions.map(q => ({
          ...clone(q),
          answers: q.answers.map(answer => clone(answer)),
          relatedUuid: q.relatedUuid && relatedMap[q.relatedUuid] || '',
          sequences: q.sequences.map(sequence => clone(sequence)),
          uuid: relatedMap[q.uuid] || uuid(),
        })),
      },
      uuid: uuid(),
    }
    this.tasks.push(cloneTask)
    this.selectedTaskIndex = this.tasks.length - 1
  }

  protected confirmDeleteTask(object: 'exercise' | 'exerciseCourse' | 'exerciseMentor') {
    this.confirm.open(
      'Удаление задания',
      'Вы действительно хотите удалить выбранное задание?',
      {
        buttonConfirmText: 'Удалить',
      },
    )
      .then(() => {
        this.handleDeleteTask(object)
      })
      .catch(() => {return})
  }

  protected handleDeleteTask(object: 'exercise' | 'exerciseCourse' | 'exerciseMentor') {
    if (this.tasks[this.selectedTaskIndex].uuid) {
      ManagerExercisesModule.deleteTask(this.tasks[this.selectedTaskIndex].uuid)
        .then(() => {
          this.deleteCurrentTask(object)
        })
        .catch((error: any) => {
          if (error.response.status === 404) {
            this.deleteCurrentTask(object)
          } else {
            this.notifyError(error)
          }
        })
    }
  }

  protected handleMenuItemClick(action: MenuActions, object: 'exercise' | 'exerciseCourse' | 'exerciseMentor') {
    const handlers = {
      [MenuActions.ADD_NEW_TASK]: this.handleAddTask,
      // [MenuActions.ADD_TEMPLATE_TASK]: this.handleAddTemplateTask,
      [MenuActions.COPY_TASK]: this.confirmCopyTask,
      [MenuActions.DELETE_TASK]: this.confirmDeleteTask,
    }

    handlers[action](object)
  }

  @Watch('isProbe')
  protected watchIsProbe () {
    if (!this.isProbe) {
      this.selectedTaskIndex = 0
    }
  }
}
