
















































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

// components
import ButtonArrow from '@/components/_uikit/buttons/ButtonArrow.vue'
import CloneCoursesListModal from '@/components/modals/exercise/CloneCoursesListModal.vue'
import DateTimeInput from '@/components/_uikit/controls/DateTimeInput.vue'
import Dialog from '@/components/modals/Dialog.vue'
import FilesList from '@/components/FilesList.vue'
import LabelDataRow from '@/components/LabelDataRow.vue'
import MentorSpeakingQuestionsView from '@/components/views/exercise/mentor/MentorSpeakingQuestionsView.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'
import Tag from '@/components/_uikit/Tag.vue'
import TaskCodingQuestion from '@/components/views/exercise/mentor/TaskCodingQuestion.vue'
import TaskPracticeQuestion from '@/components/views/exercise/mentor/TaskPracticeQuestion.vue'
import TestQuestionResultView from '@/components/views/exercise/TestQuestionResultView.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import AuthModule from '@/store/modules/auth'
import ManagerExercisesModule from '@/store/modules/manager/exercises'
import ManagerCoursesModule from '@/store/modules/manager/courses'
// types
import {
  ExerciseLargeResource,
  ProgramMonthLargeResource,
  TaskType,
} from '@/store/types'

@Component({
  components: {
    ButtonArrow,
    CloneCoursesListModal,
    DateTimeInput,
    Dialog,
    FilesList,
    LabelDataRow,
    MentorSpeakingQuestionsView,
    ModalWrapper,
    Tag,
    TaskCodingQuestion,
    TaskPracticeQuestion,
    TestQuestionResultView,
  },
})
export default class ExerciseModal extends Mixins(NotifyMixin) {
  @Prop({ default: false })
  private visible!: boolean

  @Prop({ required: true })
  private exerciseUUID!: string

  @Prop({ default: null })
  private month!: ProgramMonthLargeResource

  @Prop({ default: null })
  private courseID!: number

  @Prop({ default: false })
  private isTemplate!: boolean

  private localVisible = this.visible

  private taskIndex = 0
  private taskSpendingAt = ''
  private exercise: ExerciseLargeResource | null = null
  private showCloneCoursesListModal = false

  private get task () {
    return this.exercise && this.exercise.tasks ? this.exercise.tasks[this.taskIndex] : null
  }

  private get isCreative () {
    return this.task ? this.task.type.value === TaskType.CREATIVE : false
  }

  private get isPractice () {
    return this.task ? this.task.type.value === TaskType.PRACTICE : false
  }

  private get isTest () {
    return this.task ? this.task.type.value === TaskType.TEST : false
  }

  private get isSpeaking() {
    return this.task ? this.task.type.value === TaskType.SPEAKING : false
  }

  private get isCoding() {
    return this.task ? this.task.type.value === TaskType.CODING : false
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private mounted () {
    this.fetchExercise(this.exerciseUUID)
  }

  private fetchExercise (exerciseUUID: string) {
    if (this.month) {
      ManagerCoursesModule.fetchExercise({
        courseID: this.month.course.id,
        exerciseUUID,
        monthID: this.month.id,
      })
        .then((response: ExerciseLargeResource) => {
          this.exercise = Object.assign({}, response)
          this.taskSpendingAt = this.exercise.spendingAt
        })
        .catch((error: any) => {
          this.handleClose()
          this.notifyError(error)
        })
    } else {
      ManagerExercisesModule.fetchExercise(exerciseUUID)
        .then((response: ExerciseLargeResource) => {
          this.exercise = Object.assign({}, response)
          this.taskSpendingAt = this.exercise.spendingAt
        })
        .catch((error: any) => {
          this.handleClose()
          this.notifyError(error)
        })
    }
  }

  private handleNext () {
    if (this.exercise) {
      if (this.taskIndex < this.exercise.tasks.length - 1) {
        this.taskIndex = this.taskIndex + 1
      } else {
        this.taskIndex = 0
      }
    }
  }

  private handlePrev () {
    if (this.exercise) {
      if (this.taskIndex > 0) {
        this.taskIndex = this.taskIndex - 1
      } else {
        this.taskIndex = this.exercise.tasks.length - 1
      }
    }
  }

  private handleChangeSpendingDate () {
    this.notifyInfo('Нужен запрос с бэкенда на обновление даты проведения задания')
  }

  private handleClose () {
    this.localVisible = false
    this.exercise = null
    this.$emit('update:exerciseUUID', null)
    this.$emit('closeModal')
  }

  @Watch('exerciseUUID')
  private watchExerciseUUID (value: string, oldValue: string) {
    if (value && value !== oldValue) {
      this.fetchExercise(value)
    }
  }

  @Watch('visible')
  private watchVisible(value: boolean) {
    this.localVisible = value
  }
}
