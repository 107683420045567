


















import { Component, Prop, Vue } from 'vue-property-decorator'

// store
import { EducationLargeTaskCodingQuestionResource } from '@/store/types'

@Component
export default class TaskCodingQuestion extends Vue {
  @Prop({ required: true })
  private index!: number

  @Prop({ required: true })
  private question!: EducationLargeTaskCodingQuestionResource
}
