



















import { orderBy, uniqBy, omit } from 'lodash'
import { Bind, Debounce } from 'lodash-decorators'
import { Component, Prop, Mixins, Ref } from 'vue-property-decorator'
import * as Sentry from '@sentry/vue'

import ChatView from '@/components/views/chat/ChatView.vue'
import Confirmation from '@/components/modals/Confirmation.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
import AuthModule from '@/store/modules/auth'
import MentorExercisesModule from '@/store/modules/mentor/exercises'
import {
  CourseType,
  EducationLargeTaskResource,
  ExerciseShortResource, ExerciseStatus, MasterLargeResource,
  TaskMessageResource,
  MessageStore, TaskType,
} from '@/store/types'
import { delayAutosave } from '@/utils/constants'

const camelCaseKeys = require('camelcase-keys')

@Component({
  components: {
    ChatView,
    Confirmation,
  },
})
export default class ExercisesItemTaskMasterMessages extends Mixins(NotifyMixin, PermissionsMixin) {
  @Ref() confirm!: Confirmation

  @Prop({ required: true })
  private groupId!: number

  @Prop({ required: true })
  private exercise!: ExerciseShortResource

  @Prop({ required: true })
  private task!: EducationLargeTaskResource

  @Prop({ required: true })
  private master!: MasterLargeResource

  private get selfID() {
    if (AuthModule.self) {
      return AuthModule.self.id
    }
    return null
  }

  private get messages () {
    return orderBy(uniqBy(MentorExercisesModule.messages, 'id'), ['createdAt'])
  }

  private get isStatusWait () {
    return this.task.status.value === ExerciseStatus.WAIT || this.task.status.value === ExerciseStatus.IN_PROCESS
  }

  private get isStatusChecked () {
    return this.task.status.value === ExerciseStatus.CHECKED
  }

  private get isStatusComplete () {
    return this.task.status.value === ExerciseStatus.COMPLETE
  }

  private get isTest () {
    return this.task.type.value === TaskType.TEST
  }

  private get isSpecial(): boolean {
    return Boolean(this.exercise.course.type.value === CourseType.SPECIAL)
  }

  private get isCompletedMistakes() {
    return MentorExercisesModule.mistakes.every(mistake => mistake.completed)
  }

  private sub: any = null

  private created() {
    if (this.task.dialogInitialized) {
      this.fetchMessages()
    }
    this.sub = this.$centrifuge.newSubscription(`exercise.messages.${this.task.formId}`)
    this.sub.on('publication', (ctx: any) => {
      if (ctx.data?.event === 'Education\\Exercise\\NewMessageEvent') {
        const response = omit(ctx.data, ['event', 'socket']) as TaskMessageResource
        MentorExercisesModule.addMessage(camelCaseKeys(response, { deep: true }))
        this.$bus.$emit('clearTextarea', response)
      }
    })
    this.sub.subscribe()
  }

  private destroyed() {
    if (this.sub) {
      this.sub.unsubscribe()
      this.$centrifuge.removeSubscription(this.sub)
    }
  }

  private fetchMessages () {
    Sentry.addBreadcrumb({
      category: 'message',
      data: {
        masterID: this.$route.params.masterID,
        requestMasterGroupID: this.groupId,
        requestMasterID: this.master.user.id,
        requestTaskUUID: this.task.uuid,
        userID: this.selfID,
      },
      level: 'info',
      message: 'Данные по запросу',
    })

    if (this.master.user.id !== Number(this.$route.params.masterID))
      Sentry.captureMessage('У наставника во вкладке "сообщения" чужие сообщения мастера (22.11.2023)')

    MentorExercisesModule.fetchMessages({
      masterGroupID: this.groupId,
      masterID: this.master.user.id,
      taskUUID: this.task.uuid,
    })
      .catch(this.notifyError)
  }

  private handleSendMessage (form: MessageStore) {
    MentorExercisesModule.sendMessage({
      masterGroupID: this.groupId,
      masterID: this.master.user.id,
      params: form,
      taskUUID: this.task.uuid,
    })
      .then(() => {
        // после отправки сообщения нужно перезапрашивать таску дз, в случае, если это тест и дз относится к спецкурсу
        if (this.isTest && this.isSpecial) {
          this.$bus.$emit('rate-master-task')
        }
      })
      .catch(this.notifyError)
      .finally(() => {
        // После завершения запроса нужно менять флаг isSend в компоненте ChatMessageForm.vue
        setTimeout(() => {
          this.$bus.$emit('sendMessageEnded')
        }, delayAutosave)
      })
  }

  private handleUpdateMessage(body: MessageStore, messageID: number) {
    MentorExercisesModule.editMessage({
      body,
      masterGroupID: this.groupId,
      masterID: this.master.user.id,
      messageID,
      taskUUID: this.task.uuid,
    })
      .catch(this.notifyError)
  }

  @Debounce(300)
  @Bind
  private handleClose () {
    MentorExercisesModule.completeMasterTask({
      masterGroupID: this.groupId,
      masterID: this.master.user.id,
      taskUUID: this.task.uuid,
    })
      .then(() => {
        this.notifySuccess('Проверка завершена')
        this.$bus.$emit('close-master-task', false)
        this.$bus.$emit('rate-master-task', false)
      })
      .catch(this.notifyError)
  }

  private confirmClose () {
    this.confirm.open(
      'Завершение проверки',
      !this.isCompletedMistakes && this.task.type.value === TaskType.TEST ? 'Вы уверены, что хотите завершить проверку домашнего задания? Мастер еще не выполнил работу над ошибками' : 'Вы уверены, что хотите завершить проверку домашнего задания?',
      {
        buttonConfirmText: 'Завершить',
      },
    )
      .then(this.handleClose)
      .catch(() => {return})
  }
}
