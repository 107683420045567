





























import { Component, Prop, Ref, Watch } from 'vue-property-decorator'
import { DateTime } from 'luxon'
import { isEqual, omit } from 'lodash'
import { Debounce } from 'lodash-decorators'
import camelCaseKeys from 'camelcase-keys'
import { v4 as uuid } from 'uuid'

// components
import CodingQuestionPassingView from '@/components/views/exercise/CodingQuestionPassingView.vue'
import Confirmation from '@/components/modals/Confirmation.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import MasterEducationModule from '@/store/modules/master/education'
import MasterExercisesModule from '@/store/modules/master/exercises'
import {
  EducationLargeExerciseResource,
  EducationLargeTaskCodingQuestionResource, EducationLargeTaskDecideCodingQuestionRequest,
  EducationLargeTaskDecideRequest,
  EducationLargeTaskResource,
  EducationMasterGroupResource,
  ExerciseStatus, TaskType,
} from '@/store/types'
// utils
import { formatDate } from '@/utils/functions'
import { delayAutosave, updateTaskFormMinutes, updateTaskMinutes } from '@/utils/constants'
import AuthModule from '@/store/modules/auth'

@Component({
  components: {
    CodingQuestionPassingView,
    Confirmation,
  },
})
export default class MasterCodingTask extends NotifyMixin {
  @Ref() confirm!: Confirmation

  @Prop({ required: true })
  private exercise!: EducationLargeExerciseResource

  @Prop({ required: true })
  private masterGroup!: EducationMasterGroupResource

  @Prop({ required: true })
  private task!: EducationLargeTaskResource

  private get isStatusWait () {
    const { value } = this.task.status
    return value === ExerciseStatus.WAIT || value === ExerciseStatus.IN_PROCESS
  }

  private get answeredQuestions() {
    return this.form[this.task.uuid].questions.every((q: any) => q.answer)
  }

  private get answeredOneQuestion() {
    return this.form[this.task.uuid].questions.find((q: any) => q.answer)
  }

  private get formString() {
    return JSON.stringify(this.form[this.task.uuid].questions)
  }

  private get hash() {
    return MasterExercisesModule.task?.draftHash
  }

  private get masterID() {
    return AuthModule.self ? AuthModule.self.id : -1
  }

  private get selfID() {
    if (AuthModule.self)
      return AuthModule.self.id
    return null
  }

  // Таймштамп ухода с вкладки
  private blurFocusTimestamp = 0

  // хеш устройства пользователя
  private deviceHash = uuid()

  private form: { [key: string]: EducationLargeTaskDecideRequest } = {
    [this.task.uuid]: {
      complete: false,
      content: '',
      draftHash: null,
      firstDecide: false,
      questions: [],
    },
  }

  private isCurrentMonthDeadline = formatDate(new Date().toISOString().substr(0, 10), 'MM-yyyy') === formatDate(this.exercise.deadlineAt, 'MM-yyyy')

  // Заблокировано ли прохождение
  private isDisabled = false

  // Флаг нахождения вкладки браузера в фокусе
  private isFocusedWindow = document.hasFocus()

  private isLoading = true

  // Флаг отправки контента автосохранения
  private isLoadingForm = false

  // Есть ли соединение с интернетом
  private isOnline = navigator.onLine

  // Флаг актуальности контента
  private isRelevantContent = true

  // Последнее значение контента
  // Нужно для сравнения текущего контента, с последним отправленным на бэк, чтобы не слать повторно одно и тоже
  private lastContentValue = JSON.stringify(this.form[this.task.uuid].questions)

  // Флаг о готовности данных для отрисовки формы
  private isReady = false

  private subscribtionDraft: any = null
  private subscribtionTaskStatusUpdate: any = null

  // Флаг для определения, было вызвано событие pagehide или нет
  private terminatingEventSent = false

  // Таймштамп потери интернета
  private timestampLossInternet = 0

  // Таймштамп обновления формы
  private updateFormTimestamp = Date.now()

  private mounted() {
    window.addEventListener('online', this.connect)
    window.addEventListener('offline', this.disconnect)
    window.addEventListener('pagehide', this.handlePageHide)
    document.addEventListener('visibilitychange', this.handlePageHide)
    window.addEventListener('focus', this.handleWindowFocus)
    window.addEventListener('blur', this.handleWindowBlur)


    if (this.task.coding) {
      this.form[this.task.uuid].questions = this.task.coding.questions.map((question: EducationLargeTaskCodingQuestionResource) => ({
        answer: question.userAnswers?.length ? question.userAnswers[0].answer : null,
        questionId: question.id,
      }))
      this.isReady = true
      this.lastContentValue = JSON.stringify(this.form[this.task.uuid].questions)
      setTimeout(() => this.isLoading = false, 2500)
    } else {
      this.isLoading = false
    }

    if (this.isStatusWait) {
      this.subscribtionDraft = this.$centrifuge.newSubscription(`drafts.task.${this.selfID}.${this.task.uuid}.${this.masterID}`)
      this.subscribtionDraft.on('publication', ({ data }: any) => {
        const questions = camelCaseKeys(data.questions, { deep: true })
        if (this.isRelevantContent && this.deviceHash !== data.device_hash) {
          if ((!this.isFocusedWindow && this.hash !== data.hash) || (Date.now() - this.updateFormTimestamp > updateTaskFormMinutes * 60000)) {
            this.updateFormOnSocket(questions, data)
          } else if (!this.isFocusedWindow && this.hash !== data.hash && this.hash !== data.previous_hash) {
            this.showModalError()
          }
        }
      })
      this.subscribtionDraft.subscribe()

      this.subscribtionTaskStatusUpdate = this.$centrifuge.newSubscription(`task.${this.selfID}.${this.task.uuid}.${this.masterID}`)
      this.subscribtionTaskStatusUpdate.on('publication', ({ data }: any) => {
        const { task } = camelCaseKeys(omit(data, ['event', 'socket']), { deep: true }) as { task: EducationLargeTaskResource }
        if (!this.timestampLossInternet || task.status.value !== ExerciseStatus.WAIT || (this.timestampLossInternet && this.timestampLossInternet < task.dataTimestamp)) {
          this.$emit('updateStatusTask', task)
        }
      })
      this.subscribtionTaskStatusUpdate.subscribe()
    }
  }

  private destroyed() {
    window.removeEventListener('online', this.connect)
    window.removeEventListener('offline', this.disconnect)
    window.removeEventListener('pagehide', this.handlePageHide)
    document.removeEventListener('visibilitychange', this.handlePageHide)
    window.removeEventListener('focus', this.handleWindowFocus)
    window.removeEventListener('blur', this.handleWindowBlur)
    if (this.subscribtionDraft) {
      this.subscribtionDraft.unsubscribe()
      this.$centrifuge.removeSubscription(this.subscribtionDraft)
    }
    if (this.subscribtionTaskStatusUpdate) {
      this.subscribtionTaskStatusUpdate.unsubscribe()
      this.$centrifuge.removeSubscription(this.subscribtionTaskStatusUpdate)
    }
    this.$centrifuge.off('connected', this.connectSocket)
  }

  private connect() {
    this.$centrifuge.on('connected', this.connectSocket)
  }

  private connectSocket() {
    this.notifyInfo('Соединение с интернетом восстановлено')
    this.isDisabled = false
    this.isOnline = true
  }

  private disconnect() {
    this.$centrifuge.off('connected', this.connectSocket)
    this.timestampLossInternet = Number(Date.now().toString().slice(0, -3))
    this.isOnline = false
    this.isDisabled = true
    this.notifyInfo('Потеряно соединение с интернетом')
  }

  private handlePageHide({ type }: Event) {
    if (type === 'visibilitychange' && document.visibilityState === 'visible') {
      this.terminatingEventSent = false
    }

    if (this.lastContentValue !== JSON.stringify(this.form[this.task.uuid].questions)) {
      if (this.terminatingEventSent) return
      if (type === 'pagehide') {
        this.terminatingEventSent = true
        if (this.isRelevantContent)
          this.saveDraftTask()
        return
      }
      if (type === 'visibilitychange' && document.visibilityState === 'hidden') {
        this.terminatingEventSent = true
        if (this.isRelevantContent)
          this.saveDraftTask()
      }
    }
  }

  private handleSave () {
    this.handleSubmit(false, () => {
      this.notifySuccess('Ответ сохранен. Не забудь отправить его на проверку')
    })
  }

  private handleSend() {
    this.handleSubmit(true, () => {
      this.notifySuccess(this.exercise.autoCheck ? 'Ответ отправлен. Ключи к заданию находятся во вкладке "Сообщения"' : 'Ответ отправлен на проверку наставнику')
    })
  }

  private handleSubmit(complete: boolean, callback?: any) {
    if (complete && !this.answeredQuestions) {
      this.notifyError('На один и более вопрос не был дан ответ.')
    } else {
      if (!this.isCurrentMonthDeadline && (DateTime.fromSQL(this.exercise.verificationAt, { zone: 'Europe/Moscow' }) as any).ts < Date.now()) {
        this.confirm.open(
          'Самопроверка задания',
          'К сожалению, дедлайн проверки прошел, наставник уже не сможет проверить работу. Прикрепляем ключи для самопроверки',
          {
            buttonCancelVisible: false,
            buttonConfirmText: 'Понятно',
            hideDefaultClose: false,
            messageNoMargin: true,
            persistent: true,
            skin: 'secondary',
          },
        )
          .then(() => {
            this.submit(complete, callback)
          })
          .catch(() => {return})
      } else {
        this.submit(complete, callback)
      }
    }
  }

  private handleWindowBlur() {
    this.isFocusedWindow = false
    this.blurFocusTimestamp = Date.now()
  }

  private handleWindowFocus() {
    this.isFocusedWindow = true
    if (this.blurFocusTimestamp && Date.now() - this.blurFocusTimestamp > updateTaskMinutes * 60000) {
      window.location.reload()
    }
  }

  private saveDraft() {
    return new Promise((resolve, reject) => {
      MasterExercisesModule.saveDraftTask({
        body: {
          content: '',
          deviceHash: this.deviceHash,
          previousHash: this.task.draftHash || null,
          questions: (this.form[this.task.uuid].questions as EducationLargeTaskDecideCodingQuestionRequest[]).map(q => ({
            ...q,
            answer: q.answer ? q.answer.trim() : q.answer,
          })),
        },
        masterId: this.masterID,
        taskType: TaskType.CODING,
        taskUuid: this.task.uuid,
      })
        .then(response => {
          this.lastContentValue = JSON.stringify(response.data.questions)
          this.form[this.task.uuid].draftHash = response.data.hash
          resolve(response.data)
        })
        .catch(err => {
          if (err.code === 'ECONNABORTED') {
            this.notifyError('Изменения не сохранились. Продолжайте работу, система повторит попытку сохранения данных.')
            this.updateHashDraft()
          } else {
            if (err && err.response && err.response.status === 409) {
              this.showModalError()
              this.notifyError(err.response ? err.response.data.message : 'Содержимое изменено с другого устройства или вкладки.')
            } else {
              // Sentry.captureMessage(`При отправке драфта (мастер, тест) произошла ошибка: ${err}`)
              this.notifyError(err)
            }
          }
          reject(err)
        })
    })
  }

  private async saveDraftTask() {
    this.isLoadingForm = true
    await this.saveDraft()
      .then((response: any) => {
        this.isLoadingForm = false
        const _isEqual = isEqual((this.form[this.task.uuid].questions as EducationLargeTaskDecideCodingQuestionRequest[]).map(q => ({ ...q, answer: q.answer ? q.answer.trim() : q.answer })), response.questions)
        if (!_isEqual && this.deviceHash === response.deviceHash) {
          this.saveDraftTask()
        }
      })
      .catch(() => {
        this.isLoadingForm = false
      })
  }

  private showModalError() {
    this.isRelevantContent = false
    this.confirm && this.confirm.open(
      'Данные устарели',
      'Данные устарели, обновите страницу.',
      {
        buttonCancelVisible: false,
        buttonConfirmText: 'Обновить',
        hideDefaultClose: true,
        persistent: true,
      },
    )
      .then(() => window.location.reload())
      .catch(() => {return})
  }

  private submit(complete: boolean, callback?: any) {
    MasterExercisesModule.executeExercisePracticeAndTestTask({
      exerciseUUID: this.task.exerciseUuid,
      masterGroupID: this.masterGroup.id,
      params: {
        ...this.form[this.task.uuid],
        complete,
        content: '',
      },
      taskUUID: this.task.uuid,
    })
      .then(() => {
        if (typeof callback === 'function') {
          callback()
        }
        this.$emit('submit', complete)
        this.$bus.$emit('reloadCodingQuestion') // После отправки дз на проверку нужно перезагрузить редактор кода в компоненте CodingQuestionPassingView.vue

        // После отправки дз обновляем список дз на странице /master/exercises
        if (MasterEducationModule.currentMasterGroup && complete) {
          MasterExercisesModule.setExerciseContainersFilterMonth(this.exercise.monthId)
          MasterExercisesModule.fetchExerciseContainers({
            masterGroupID: MasterEducationModule.currentMasterGroup.id,
            params: MasterExercisesModule.exerciseContainersFilter,
          })
            .catch(this.notifyError)
        }
      })
      .catch(this.notifyError)
  }

  private updateFormOnSocket(questions: EducationLargeTaskDecideCodingQuestionRequest[], data: any) {
    this.lastContentValue = JSON.stringify(questions)
    this.form[this.task.uuid].questions = questions
    this.form[this.task.uuid].draftHash = data.hash
    MasterExercisesModule.setHashTask(data.hash)

    // Обновляем отображение формы
    setTimeout(() => {
      this.$bus.$emit('updateCodingFormOnSocket')
    })
  }

  private updateHashDraft() {
    MasterExercisesModule.fetchDraftTask({
      masterId: this.masterID,
      taskType: TaskType.CODING,
      taskUuid: this.task.uuid,
    })
  }

  @Debounce(delayAutosave)
  @Watch('formString')
  private watchFormString(value: string) {
    this.updateFormTimestamp = Date.now()
    if (!this.isLoading && this.lastContentValue !== value) {
      if (!this.isLoadingForm) {
        this.saveDraftTask()
      }
    }
  }
}
