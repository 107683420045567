


















































import { omit } from 'lodash'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { v4 as uuid } from 'uuid'

import {
  PracticeQuestionRequest,
  SpeakingSubQuestionRequest,
  SpeakingQuestionRequest,
  TestAnswerRequest,
  TestQuestionRequest,
  TestSequenceRequest,
  TaskType, CodingQuestionRequest,
} from '@/store/types'
import {
  GET_DEFAULT_CODING_QUESTION_FORM,
  GET_DEFAULT_PRACTICE_QUESTION_FORM,
  GET_DEFAULT_SPEAKING_QUESTION_FORM,
  GET_DEFAULT_TEST_QUESTION_FORM,
} from '@/components/forms/exercise/constants'
import ExerciseTaskQuestionLoaded from '@/components/forms/exercise/ExerciseTaskQuestionLoaded.vue'

@Component({
  components: {
    ExerciseTaskQuestionLoaded,
  },
})
export default class ExerciseTaskQuestionWrapper extends Vue {
  @Prop({ required: true })
  private form!: PracticeQuestionRequest | TestQuestionRequest | SpeakingQuestionRequest | CodingQuestionRequest

  @Prop({ required: true })
  private index!: number

  @Prop({ required: true })
  private type!: 'practice' | 'test' | 'speaking' | 'coding'

  @Prop({ default: false })
  private isLast!: boolean

  @Prop({ default: 0 })
  private lastVisibleIndex!: number

  // Дата выдачи д/з наступила?
  @Prop({ default: false })
  private isSpendingAt!: boolean

  @Prop({ default: true })
  private isOutOfSight!: boolean

  // Наблюдатель
  private observer = new IntersectionObserver(
    this.handleIntersection,
    {
      // root: document.getElementsByClassName('v-application--wrap').item(0),
      root: null,
      rootMargin: '0px',
      threshold: 0,
    },
  )

  private height = 'auto'

  private get isFluidView() {
    return !this.isSpendingAt || this.type !== TaskType.TEST && this.type !== TaskType.SPEAKING
  }

  private mounted () {
    this.observer.observe(this.$el)
  }

  private destroyed() {
    this.observer.unobserve(this.$el)
  }

  // private handleIntersection () {
  private handleIntersection(entries: IntersectionObserverEntry[]) {
    if (entries[0].intersectionRatio > 0) {
      this.height = 'auto'
      this.$emit('came-into-view', this.index)
    }
  }

  private handleAddForm () {
    switch (this.type) {
    case 'practice':
      this.$emit('add-form', this.index + 1, GET_DEFAULT_PRACTICE_QUESTION_FORM())
      break
    case 'test':
      this.$emit('add-form', this.index + 1, GET_DEFAULT_TEST_QUESTION_FORM())
      break
    case 'speaking':
      this.$emit('add-form', this.index + 1, GET_DEFAULT_SPEAKING_QUESTION_FORM())
      break
    case 'coding':
      this.$emit('add-form', this.index + 1, GET_DEFAULT_CODING_QUESTION_FORM())
    }
  }

  private handleCopyForm () {
    switch (this.type) {
    case 'practice':
      this.$emit('add-form', this.index + 1, { ...omit(this.form, 'id'), uuid: uuid() })
      break
    case 'test':
      this.$emit('add-form', this.index + 1, {
        ...omit(this.form, 'id'),
        answers: (this.form as TestQuestionRequest).answers.map((answer: TestAnswerRequest) => omit(answer, 'id')),
        sequences: (this.form as TestQuestionRequest).sequences.map((sequence: TestSequenceRequest) => omit(sequence, 'id')),
        uuid: uuid(),
      })
      break
    case 'speaking':
      this.$emit('add-form', this.index + 1, {
        ...omit(this.form, 'id'),
        questions: (this.form as SpeakingQuestionRequest).questions.map((answer: SpeakingSubQuestionRequest) => omit(answer, 'id')),
      })
      break
    case 'coding':
      this.$emit('add-form', this.index + 1, { ...omit(this.form, 'id'), uuid: uuid() })
    }
  }

  private handleDeleteForm () {
    this.$emit('delete-form', this.index)
  }

  public fixCardHeight () {
    this.height = this.$el.scrollHeight + 'px'
  }
}
