













import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

// components
import NewYearHat from '@/components/NewYearHat.vue'

@Component({
  components: {
    NewYearHat,
  },
})
export default class Dialog extends Vue {
  @Prop({ required: true })
  private visible!: boolean

  @Prop({ default: 600 })
  private width!: number

  @Prop({ default: true })
  private persistent!: boolean

  @Prop({ default: undefined })
  private contentClass!: string

  private localVisible = this.visible

  private handleClose(value: boolean) {
    this.localVisible = value
    this.$emit('closeDialog', this.localVisible)
  }

  @Watch('visible')
  private watchVisible(value: boolean) {
    this.localVisible = value
  }
}
