






import { Component, Prop, Vue } from 'vue-property-decorator'
import { Route } from 'vue-router/types/router'

import SystemModule from '@/store/modules/system'
import { isNewYear } from '@/utils/constants'

@Component
export default class Logo extends Vue {
  @Prop({ default: false })
  private disabled!: boolean

  @Prop({ default: null })
  private to!: string | Route

  @Prop({ default: 'md' })
  private size!: 'sm' | 'md' | 'lg' | 'xl'

  private get interface () {
    return SystemModule.interface
  }

  private get isNewYear() {
    return isNewYear
  }

  private get href () {
    return this.to ? this.to : { name: this.interface === 'master' ? 'master' : 'manager' }
  }
}
