

















































import { Component, Prop, Ref } from 'vue-property-decorator'
import loader from '@monaco-editor/loader'
import * as CodeReview from 'monaco-review'
import { ReviewCommentEvent, ReviewManager } from 'monaco-review'

// components
import Attachment from '@/components/_uikit/Attachment.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import MasterExercisesModule from '@/store/modules/master/exercises'
import {
  CodeExecutionResult, CodingRateCommentResource,
  EducationLargeTaskCodingQuestionResource,
  EducationLargeTaskDecideCodingQuestionRequest,
} from '@/store/types'

@Component({
  components: {
    Attachment,
  },
})
export default class CodingQuestionPassingView extends NotifyMixin {
  @Ref() monacoEditor!: HTMLElement

  @Prop({ required: true })
  private formQuestion!: EducationLargeTaskDecideCodingQuestionRequest

  @Prop({ required: true })
  private index!: number

  @Prop({ required: true })
  private question!: EducationLargeTaskCodingQuestionResource

  @Prop({ required: true })
  private readonly!: boolean

  private get exerciseUUID() {
    return this.$route.params.exerciseUUID
  }

  private get masterGroupID() {
    return this.$route.params.groupID
  }

  private get taskUUID() {
    return this.$route.params.taskUUID
  }

  private result: CodeExecutionResult | null = null
  private reviewManager: ReviewManager | null = null

  private monacoEditorRef: any = null

  private commentsHeightCache: Record<string, number> = {}

  private mounted() {
    loader.config({ paths: { vs: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.52.2/min/vs' } })
    loader.init()
      .then(monacoInstance => {
        this.monacoEditorRef = monacoInstance.editor.create(this.monacoEditor, {
          automaticLayout: true,
          folding: false,
          language: 'python',
          readOnly: this.readonly,
          theme: this.$vuetify.theme.dark ? 'vs-dark' : 'vs',
          value: this.formQuestion.answer,
        })
        const model = this.monacoEditorRef.getModel()
        model.onDidChangeContent(() => {
          this.formQuestion.answer = model.getValue()
        })

        this.$bus.$on('updateCodingFormOnSocket', this.updateCodingFormOnSocket)
        this.$bus.$on('reloadCodingQuestion', this.reloadMonacoEditor)

        if (this.readonly) {
          this.reviewManager = CodeReview.createReviewManager(
            this.monacoEditorRef,
            'user-1',
            /* eslint-disable-next-line */
            /* @ts-ignore:next-line */
            this.convertDataToState(this.question.rateComments),
            () => {
              return
            },
            {
              editButtonAddText: 'Ответить',
              editButtonRemoveText: 'Удалить',
              readOnly: true,
            },
          )
          setTimeout(() => {
            this.renderCloneComments()
            this.renderHeightComments()
          }, 500)
          window.addEventListener('resize', this.resizeHeightComments)
        }
      })
  }

  private destroyed() {
    window.removeEventListener('resize', this.resizeHeightComments)
    this.$bus.$off('updateCodingFormOnSocket', this.updateCodingFormOnSocket as any)
    this.$bus.$off('reloadCodingQuestion', this.reloadMonacoEditor as any)
  }

  private reloadMonacoEditor() {
    if (this.monacoEditorRef) {
      this.monacoEditorRef.dispose()

      loader.config({ paths: { vs: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.52.2/min/vs' } })
      loader.init()
        .then(monacoInstance => {
          this.monacoEditorRef = monacoInstance.editor.create(this.monacoEditor, {
            automaticLayout: true,
            folding: false,
            language: 'python',
            readOnly: this.readonly,
            theme: this.$vuetify.theme.dark ? 'vs-dark' : 'vs',
            value: this.formQuestion.answer,
          })

          if (this.readonly) {
            this.reviewManager = CodeReview.createReviewManager(
              this.monacoEditorRef,
              'user-1',
              /* eslint-disable-next-line */
              /* @ts-ignore:next-line */
              this.convertDataToState(this.question.rateComments),
              () => {
                return
              },
              {
                editButtonAddText: 'Ответить',
                editButtonRemoveText: 'Удалить',
                readOnly: true,
              },
            )
            setTimeout(() => {
              this.renderCloneComments()
              this.renderHeightComments()
            }, 500)
          }
        })
    }
  }

  // Клонирую элемент комментария, чтобы знать его высоту и сделать его скрытым
  private renderCloneComments() {
    if (this.reviewManager) {
      const viewZonesEl: HTMLDivElement | null = document.querySelector('.view-zones')
      for (let key in this.reviewManager.renderStore) {
        const { viewZoneId } = this.reviewManager.renderStore[key]
        const parentEl: HTMLDivElement | null = document.querySelector(`[monaco-view-zone="${viewZoneId}"]`)
        let cloneNode: Node | null = null

        if (parentEl && viewZonesEl) {
          cloneNode = parentEl.cloneNode(true)
          ;(cloneNode as any).style = 'display: block; visibility: hidden;'
          ;(cloneNode as any).dataset.uuid = key
          ;(cloneNode as any).dataset.number = '1'
          viewZonesEl.appendChild(cloneNode)
          this.commentsHeightCache[`${key}-1`] = (cloneNode as any).offsetHeight
        }
      }
    }
  }

  // Переопределение высоты комментариев (при рендеринге компонента или изменении ширины экрана)
  private renderHeightComments() {
    if (this.reviewManager) {
      this.reviewManager.commentHeightCache = {
        ...this.commentsHeightCache,
      }
      this.reviewManager.loadFromStore(this.reviewManager?.store, this.reviewManager?.events)
    }
  }

  private resizeHeightComments() {
    const comments: NodeListOf<HTMLDivElement> = this.monacoEditor.querySelectorAll('.reviewComment[data-uuid]')
    comments.forEach(el => {
      const { number, uuid } = el.dataset
      this.commentsHeightCache[`${uuid}-${number}`] = el.offsetHeight
    })
    this.renderHeightComments()
  }

  private runCode() {
    MasterExercisesModule.executeCodeMaster({
      body: {
        code: this.formQuestion.answer,
        questionId: this.formQuestion.questionId,
      },
      exerciseUUID: this.exerciseUUID,
      masterGroupID: +this.masterGroupID,
      taskUUID: this.taskUUID,
    })
      .then(response => {
        this.result = response
      })
      .catch(this.notifyError)
  }

  private convertDataToState(data: CodingRateCommentResource[]): ReviewCommentEvent[] {
    return data.map(comment => ({
      createdAt: 0,
      createdBy: '',
      id: comment.uuid,
      lineNumber: comment.answerLine ?? 0,
      text: comment.mentorComment ?? '',
      type: 'create',
    }))
  }

  private updateCodingFormOnSocket() {
    if (this.monacoEditorRef) {
      this.monacoEditorRef.setValue(this.formQuestion.answer)
    }
  }
}
