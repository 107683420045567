

































import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Component, Prop, Vue } from 'vue-property-decorator'

import ModalWrapper from '@/components/modals/ModalWrapper.vue'
import NewYearHat from '@/components/NewYearHat.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'

@Component({
  components: {
    ModalWrapper,
    NewYearHat,
    TextInput,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class WysiwygLinkModal extends Vue {
  @Prop({ required: true })
  private visible!: boolean

  private form = {
    blank: true,
    url: '',
  }

  private handleConfirm () {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          this.$emit('submit', this.form)
          this.handleCancel()
        }
      })
  }

  private handleCancel () {
    this.$emit('update:visible', false)
  }
}
