import { omit, sortBy } from 'lodash'
import { DateTime } from 'luxon'
import qs from 'qs'
import Cookies from 'js-cookie'

import {
  FullUsefulMaterial,
  NameValueResource,
  Permission,
  UsefulMaterialPatchBody,
  UsefulMaterialPostBody,
} from '@/store/types'
import { IUsefulMaterialForm } from '@/store/types/forms'
import { CourseSubjectType } from '@/store/types/progress'
import { timeZoneName, timeZoneOffset } from '@/utils/constants'

const snakeCaseKeys = require('snakecase-keys')


// Перевод первой буквы строки к верхнему регистру
export function capitalize (value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

// Клонирование объекта с изъятием атрибута id и uuid
export const clone = (obj: any) => {
  return omit(obj, ['id', 'uuid'])
}

// Подстановка правильного склонения в зависимости от количества
export function declension (n: number, t: string[]) {
  return t[(n %= 100, 20 > n && n > 4) ? 2 :[2,0,1,1,1,2][(n %= 10, n < 5) ? n : 5]]
}

export function hasPermission (rule: Permission, permissions: string[] = []): boolean {
  return permissions.some((permission: string) => permission === rule)
}

// Мутация параметров пагинации к внутреннему типу данных
export function filterOptionsToParams (options: any) {
  for (const prop in options) {
    if (typeof options[prop] === 'boolean') {
      options[prop] = options[prop] ? 1 : 0
    }

    if (!options[prop] && options[prop] !== 0) {
      delete options[prop]
    }
  }

  return options
}

// Функция принимает массив месяцов и отдает текщий или первый в выборке для селекта
export function getCurrentMonth (months: NameValueResource[]) {
  if (months.length > 0) {
    const dt = DateTime.fromMillis(Date.now())
    return months.find(month => {
      return dt.toISODate().substring(0, 7) === month.name.substring(0, 7)
    }) || { name: formatDate(months[0].name), value: months[0].value }
  }
  return null
}

// Функция конвертации времени устройста в московское
export function convertDateToMSK(date: string, localTimezone: boolean, mask = 'yyyy-MM-dd HH:mm:ss') {
  let formattedDate = capitalize(DateTime.fromSQL(date).minus({ hours: localTimezone ? timeZoneOffset : 0 }).toFormat(mask))

  if (mask.indexOf('MMM') >= 0 && mask.indexOf('MMMM') < 0) {
    formattedDate = formattedDate.replace('.', '')
  }

  return formattedDate
}

// Форматирование даты
export function formatDate (date: string, mask = 'dd.MM.yyyy', localTimezone = false) {
  let formattedDate = capitalize(DateTime.fromSQL(date).setZone(!localTimezone ? 'Europe/Moscow' : timeZoneName).toFormat(mask))

  if (mask.indexOf('MMM') >= 0 && mask.indexOf('MMMM') < 0) {
    formattedDate = formattedDate.replace('.', '')
  }

  return formattedDate
}

// Форматирование диапазона дат
export function formatDateRange(start: string, end: string) {
  const mask = 'd MMM yyyy'
  const startMonth = start.slice(0,10).split('-')
  const endMonth = end.slice(0,10).split('-')

  let formattedStartDate = ''

  let formattedEndDate = capitalize(DateTime.fromSQL(end).toFormat(mask))

  if (mask.indexOf('MMM') >= 0 && mask.indexOf('MMMM') < 0) {
    formattedEndDate = formattedEndDate.replace('.', '')
  }

  // Если и день и месяц и год одинаковы
  if (startMonth[0] === endMonth[0] && startMonth[1] === endMonth[1] && startMonth[2] === endMonth[2]) {
    return formattedEndDate
  }
  // Если дни разные, а месяц и год одинаковый
  if (startMonth[0] === endMonth[0] && startMonth[1] === endMonth[1] && startMonth[2] !== endMonth[2]) {
    formattedStartDate = capitalize(DateTime.fromSQL(start).toFormat('d'))
  } else if (startMonth[0] === endMonth[0] && startMonth[1] !== endMonth[1]) {
    formattedStartDate = capitalize(DateTime.fromSQL(start).toFormat('d MMM'))
  } else {
    formattedStartDate = capitalize(DateTime.fromSQL(start).toFormat(mask))
  }

  if (mask.indexOf('MMM') >= 0 && mask.indexOf('MMMM') < 0) {
    formattedStartDate = formattedStartDate.replace('.', '')
  }

  return `${formattedStartDate} - ${formattedEndDate}`
}

// Разбиение списка по половине
export const getHalfsOfList = (list: any[]) => {
  const listCopy = [...list]
  const half = Math.ceil(listCopy.length / 2)
  return {
    head: listCopy.splice(0, half),
    tail: listCopy.splice(-half),
  }
}

// Генерация названия продолжительности пакета
export const getMonthsRange = (list: NameValueResource[], mask = 'LLLL') => {
  const orderedList = sortBy(list, 'name')
  return orderedList.length > 1
    ? `${formatDate(orderedList[0].name, mask)} -&nbsp;${formatDate(orderedList[orderedList.length - 1].name, mask)}`
    : formatDate(orderedList[0].name, mask)
}

export const getMonthsRangeForPackages = (list: NameValueResource[], mask = 'LLLL') => {
  const orderedList = sortBy(list, 'name')
  return orderedList.length > 1
    ? `${formatDate(orderedList[0].name, mask)} - ${formatDate(orderedList[orderedList.length - 1].name, mask)}`
    : formatDate(orderedList[0].name, mask)
}

// Функция для получения процентов
export function getPercent (total: number, completion: number) {
  if (!total || !completion) {
    return 0
  }
  return Math.floor((completion / total) * 100)
}

// Функция для преобразования фильтров в строку для get запросов
export function getQueryStringFromParams (params: any) {
  return qs.stringify(params, {
    arrayFormat: 'indices',
    encode: true,
  })
}

// Редирект пользователя на новый таб. Используется, например, для скачивания выгрузок
export function openNewTab (link: string, filter: any, baseURL?: string) {
  const endpoint = baseURL || process.env.VUE_APP_BACKEND_API_ENDPOINT
  const filterQueryString = filter ? `?${getQueryStringFromParams(snakeCaseKeys(tableOptionsToParams(filter)))}` : ''
  const tab = window.open(`${endpoint}${link}${filterQueryString}`, '_blank')

  if (tab) {
    tab.focus()
  }
}

// Мутация параметров компонента VDataTable к внутреннему типу данных
export function tableOptionsToParams (options: any) {
  let sortOrder = undefined

  for (const prop in options) {
    if (typeof options[prop] === 'boolean') {
      options[prop] = options[prop] ? 1 : 0
    }

    if (!options[prop]) {
      delete options[prop]
    }
  }

  if (options.sortDesc && options.sortDesc.length) {
    if (options.sortDesc[0]) {
      sortOrder = 'desc'
    } else {
      sortOrder = 'asc'
    }
  }

  return {
    ...omit(options, ['groupBy', 'groupDesc', 'itemsPerPage', 'multiSort', 'mustSort', 'sortBy', 'sortDesc']),
    perPage: options.itemsPerPage || 25,
    sortField: options.sortBy && options.sortBy.length ? options.sortBy[0] : undefined,
    sortOrder,
  }
}

// Данная функция нужна для парсинга даты, одинаково во всех бразуерах - в том числе и сафари
export function parseDateToMilliseconds(date: string): number {
  return (DateTime.fromSQL(date, { zone: 'Europe/Moscow' }) as any).ts
}

// Функция преобразования секунд в формат времени - 3661 => 1:01:01
export function convertSecondsToTime(seconds: number | null): string {
  if (seconds === null) {
    return ''
  }
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor(seconds / 60) - (hours * 60)
  const second = seconds - hours * 3600 - minutes * 60
  return `${hours}:${minutes < 10 ? `0${minutes}` : minutes}:${second < 10 ? `0${second}` : second}`
}

// Функция преобразования формата времени в секунды - 1:01:01 => 3661
export function convertTimeToSeconds(time: string): number {
  const [hours, minutes, seconds] = time.split(':')
  return +seconds + +minutes * 60 + +hours * 3600
}

// Функция преобразования секунд в часы и минуты - 3660 => 01:01
export function convertSecondsToHoursMinutes(seconds: number | null, showSeconds = false): string {
  if (seconds === null) {
    return ''
  }
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor(seconds / 60) - (hours * 60)
  const _seconds = seconds % 60

  return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}${showSeconds ? `:${_seconds < 10 ? `0${_seconds}` : _seconds}` : ''}`
}

// Функция преобразования часов и минут в секунды - 01:01 => 3660
export function convertHoursMinutesToSeconds(time: string): number {
  const [hours, minutes] = time.split(':')
  return +minutes * 60 + +hours * 3600
}

// Функция для определения количества дней в месяце - 2024-02-01 00:00:00 => 29
export function countDayInMonth(date: string): number {
  const splitDate: string[] = date.split(' ')[0].split('-')

  // [month, year]
  const month: number[] = [+splitDate[1], +splitDate[0]]

  return DateTime.local(month[1], month[0]).daysInMonth
}

export function transformRequestMaterialForm(form: IUsefulMaterialForm, isLocalTimezone: boolean, courseId?: number): UsefulMaterialPostBody | UsefulMaterialPatchBody {
  const request: UsefulMaterialPostBody | UsefulMaterialPatchBody = {
    content: JSON.stringify(form.content),
    courseIds: courseId ? [...form.courseIds, courseId] : form.courseIds,
    mediaIds: form.mediaIds,
    publishDatetime: convertDateToMSK(form.publishDatetime, isLocalTimezone),
    title: form.title,
    type: form.type,
  }

  if (courseId) {
    (request as UsefulMaterialPatchBody).isNotify = form.isNotify
  }

  return request
}

export function transformResponseMaterialForm(courseId: number, response: FullUsefulMaterial, isLocalTimezone: boolean): IUsefulMaterialForm {
  const form = {
    content: JSON.parse(response.content),
    courseIds: response.courses.filter(course => course.value !== courseId).map(course => course.value),
    mediaIds: response.media.map(media => media.id),
    publishDatetime: formatDate(response.publishDatetime, 'yyyy-MM-dd HH:mm:ss', isLocalTimezone),
    title: response.title,
    type: response.type,
  }

  if (courseId) {
    (form as IUsefulMaterialForm).isNotify = false
  }

  return form
}

// Функция для определения родительских папок для компонента Treeview
export function parentsTreeById(id: number, tree: any[], parents: number[] = []): number[] | boolean {
  for (let i = 0; i < tree.length; i++) {
    if (tree[i].id === id) {
      return parents
    } else if (tree[i].children.length && parentsTreeById(id, tree[i].children, parents)) {
      parents.push(tree[i].id)
      return parents
    }
  }
  return false
}

// Сравнение месяцев
export function compareMonths(date: string, date2?: string) {
  if (date2) {
    return DateTime.fromSQL(date).toFormat('MM-yyyy') === DateTime.fromSQL(date).toFormat('MM-yyyy')
  } else {
    return DateTime.fromSQL(date).toFormat('MM-yyyy') === DateTime.now().toFormat('MM-yyyy')
  }
}

export const deepObjectChange = (obj: any, path: string[], value: any) => {
  Object.keys(obj).forEach(() => {
    if (path[0] in obj) {
      if (path.length === 1) {
        obj[path[0]] = value
      }

      const objectToDive = obj[path[0]]
      path.shift()
      return {
        ...deepObjectChange(objectToDive, path, value),
      }
    }
    return
  })

  return obj
}

// Вывод предмета - history => История
export const transformSubject = (subject: CourseSubjectType) => {
  switch (subject) {
  case CourseSubjectType.HISTORY:
    return 'История'
  case CourseSubjectType.LAW_SCHOOL:
    return 'Юр. школа'
  case CourseSubjectType.LITERATURE:
    return 'Литература'
  case CourseSubjectType.OLYMPIAD:
    return 'Олимпиада история'
  case CourseSubjectType.SOCIAL:
    return 'Обществознание'
  case CourseSubjectType.OLYMPIAD_SOCIAL_SCIENCE:
    return 'Олимпиада обществознание'
  case CourseSubjectType.RUSSIAN_LANGUAGE:
    return 'Русский язык'
  case CourseSubjectType.ENGLISH_LANGUAGE:
    return 'Английский язык'
  case CourseSubjectType.BIOLOGY:
    return 'Биология'
  case CourseSubjectType.CHEMISTRY:
    return 'Химия'
  default:
    return 'История'
  }
}

// Функции для временного хранения данных в localStorage
export function removeStorage(name: string, previous = false): boolean {
  try {
    if (previous) {
      localStorage.removeItem(`${name}_${process.env.VUE_APP_PREVIOUS_STORAGE_VERSION}`)
      localStorage.removeItem(`${name}_${process.env.VUE_APP_PREVIOUS_STORAGE_VERSION}_expiresIn`)
      return true
    }
    localStorage.removeItem(`${name}_${process.env.VUE_APP_CURRENT_STORAGE_VERSION}`)
    localStorage.removeItem(`${name}_${process.env.VUE_APP_CURRENT_STORAGE_VERSION}_expiresIn`)
  } catch (e) {
    // eslint-disable-next-line
    console.error(`removeStorage: Error removing key ${name} from localStorage: ${JSON.stringify(e)}`)
    return false
  }
  return true
}

export function getStorage<T>(name: string): T | null {
  const now = Date.now()
  let expiresIn = Number(localStorage.getItem(`${name}_${process.env.VUE_APP_CURRENT_STORAGE_VERSION}_expiresIn` || '0'))
  if (!expiresIn)
    expiresIn = 0
  if (process.env.VUE_APP_PREVIOUS_STORAGE_VERSION) {
    removeStorage(name, true)
  }
  if (expiresIn < now) {
    removeStorage(name)
    return null
  } else {
    try {
      return JSON.parse(localStorage.getItem(`${name}_${process.env.VUE_APP_CURRENT_STORAGE_VERSION}`) || 'null')
    } catch(e) {
      // eslint-disable-next-line
      console.error(`getStorage: Error reading key ${name} from localStorage: ${JSON.stringify(e)}`)
      return null
    }
  }
}

export function setStorage(key: string, value: any, expires = 1): boolean {
  expires = Math.abs(expires) * 24 * 60 * 60
  const now = Date.now()
  const schedule = now + expires * 1000

  try {
    localStorage.setItem(`${key}_${process.env.VUE_APP_CURRENT_STORAGE_VERSION}`, JSON.stringify(value))
    localStorage.setItem(`${key}_${process.env.VUE_APP_CURRENT_STORAGE_VERSION}_expiresIn`, `${schedule}`)
  } catch (e) {
    // eslint-disable-next-line
    console.error(`setStorage: Error setting key ${key} in localStorage: ${JSON.stringify(e)}`)
    return false
  }
  return true
}

// Очистка кэша запросов при разлогине или протухании токена
export function clearStorage(): void {
  localStorage.removeItem('interface')
  Cookies.remove('news')
  Cookies.remove('sales')
  Cookies.remove('messageCounter')
}

// Очищаем ключ redirect в localStorage
export function clearRedirectStorage(): void {
  localStorage.removeItem('redirect')
}

// Расчет количества прошедших месяцев между датами
// текущая дата 23.01.2024
// заданная дата 15.11.2023
// => 2
export function howMonthsPassed(startDate: string, endDate?: string): number {
  const now = endDate ? DateTime.fromSQL(endDate) : DateTime.now()
  const date = DateTime.fromSQL(startDate)
  const yearNow = now.year
  const yearDate = date.year
  const monthNow = now.month
  const monthDate = date.month
  return (yearNow - yearDate) * 12 + (monthNow - monthDate)
}

export function randomColorBanner(): string {
  const colors = ['orange', 'teal', 'blue', 'purple', 'green', 'coral']
  return colors[Math.floor(Math.random() * colors.length)]
}

// Получение изменений объекта
export function findObjectDifferences(obj1: any, obj2: any) {
  const changes: Record<any, any> = {}

  function compare(value1: any, value2: any, path = '') {
    // Если значения одинаковы, ничего не делать
    if (value1 === value2) return

    // Проверка на массив
    const isArray1 = Array.isArray(value1)
    const isArray2 = Array.isArray(value2)

    if (isArray1 && isArray2) {
      // Если оба значения массивы, сравниваем их длины
      if (value1.length !== value2.length) {
        changes[path] = {
          oldValue: value1,
          newValue: value2,
        }
      } else {
        // Сравниваем элементы массивов поэлементно
        for (let i = 0; i < value1.length; i++) {
          const newPath = `${path}[${i}]`
          compare(value1[i], value2[i], newPath)
        }
      }
    } else if (typeof value1 === 'object' && value1 !== null && typeof value2 === 'object' && value2 !== null) {
      // Если оба значения объекты, рекурсивно сравниваем их
      const keys1 = Object.keys(value1)
      const keys2 = Object.keys(value2)
      const allKeys = new Set([...keys1, ...keys2])

      for (const key of allKeys) {
        const newPath = path ? `${path}.${key}` : key
        compare(value1[key], value2[key], newPath)
      }
    } else {
      // Если значения разные, сохраняем изменения
      changes[path] = {
        oldValue: value1,
        newValue: value2,
      }
    }
  }

  compare(obj1, obj2)
  return changes
}

// export const objectByString = <PassedObject extends Object>(o: PassedObject, s: string, payload: any) => {
//   s = s.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
//   s = s.replace(/^\./, '')           // strip a leading dot
//   let a = s.split('.')
//   let localO = {...o}

//   const closureCreator = (oLocal: any) => {
//     let localStateOfO = { ...oLocal}

//     // code

//     return

//     // return (field: string) => {
//     //   if (isInObject(localStateOfO, field)) {
//     //     localStateOfO[field] = localStateOfO[field]
//     //     return localStateOfO
//     //   }
//     //   return
//     // }
//   }
//   // const createdClosure = closureCreator(localO)
//   // let result: any = {}
//   // a.forEach(field => {
//   //   isInObject(result, field)

//   //   const scopedO = createdClosure(field)
//   //   result[field] = scopedO ? { ...scopedO } : result
//   // })

//   // for (let i = 0, n = a.length; i < n; ++i) {
//   //     let k = a[i];
//   //     if (k in localO) {
//   //       is
//   //     } else {
//   //         return;
//   //     }
//   // }
//   // return o;
// }

// function isInObject (object: any, field: string) {
//   return field in object
// }

export function sendMetrik(action: string) {
  if (typeof window !== 'undefined' && typeof window.ym !== 'undefined') {
    if (process.env.VUE_APP_YM_ENV === 'production') {
      window.ym(process.env.VUE_APP_YM_ID_SECOND, 'reachGoal', action)
    } else {
      // eslint-disable-next-line
      console.log('sendMetrik', process.env.VUE_APP_YM_ID_SECOND, action)
    }
  }
}
