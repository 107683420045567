import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store from '@/store'
import {
  ChangeEmailAndPassword,
  MasterOrdersStatsGetRequest,
  ProfileCoursesGetRequest,
  ProfileEmailCustomPatchRequest,
  ProfileEmailPatchRequest,
  PaymentRecurrentCancelCourseIdPutRequest,
  ProfileCoursesCourseIdPaymentScheduleGetRequest,
  ProfileGetRequest,
  ProfileNotificationsGetParams,
  ProfileNotificationsGetRequest,
  ProfileNotificationsPatchParams,
  ProfileNotificationsPatchRequest,
  ProfilePasswordPatchRequest,
  ProfilePasswordPostRequest,
  ProfilePatchRequest,
  UserChangeEmail,
  UserLargeResource,
  UserNotificationResource,
  UserNotificationsRequest,
  UserPassword,
  UserProfile,
  UserSetPassword,
  ProfileThematicAvatarListGetRequest,
  ProfileThematicAvatarSetPutRequest,
  ThematicAvatarResource,
} from '@/store/types'
import AuthModule from '@/store/modules/auth'

/**
 * Работа с профилем
 * - управление профилем
 * - смена пароля
 * - управление уведомлениями
 */

@Module({
  dynamic: true,
  name: 'Profile',
  namespaced: true,
  store,
})
class Profile extends VuexModule {
  // ---------------------------- Profile data ---------------------------- >>

  information: UserLargeResource | null = null
  requiredSocials = ['vkontakte', 'telegram']

  @Mutation
  setInformation (response: UserLargeResource) {
    this.information = Object.assign({}, response)
  }

  @Action({ rawError: true })
  async fetchInformation () {
    const { data } = await ProfileGetRequest()

    this.setInformation(data)

    return data
  }

  @Action({ rawError: true })
  async fetchProfileCourses() {
    const { data } = await ProfileCoursesGetRequest()
    return data
  }

  @Action({ rawError: true })
  async fetchCourseGraphicPayments(courseID: number) {
    const { data } = await ProfileCoursesCourseIdPaymentScheduleGetRequest(courseID)
    return data
  }

  @Action({ rawError: true })
  async cancelRecurrentPayment(courseID: number) {
    const { data } = await PaymentRecurrentCancelCourseIdPutRequest(courseID)
    return data
  }

  @Action({ rawError: true })
  async updateInformation (payload: UserProfile) {
    const { data } = await ProfilePatchRequest({
      ...payload,
      socialIds: payload.socialIds && payload.socialIds.length ? payload.socialIds : [],
    })

    this.setInformation(data)
    AuthModule.setSelfFromProfile(data)

    return data
  }

  @Action({ rawError: true })
  async updatePassword (payload: UserPassword) {
    const { data } = await ProfilePasswordPatchRequest(payload)
    AuthModule.setSelf(data.user)
    AuthModule.setToken(data.token)
  }

  @Action({ rawError: true })
  async savePassword(payload: UserSetPassword) {
    const { data } = await ProfilePasswordPostRequest(payload)
    if (this.information) {
      this.setInformation({
        ...this.information,
        email: data.email,
        hasPassword: true,
      })
    }
    return data
  }

  @Action({ rawError: true })
  async updateEmail(payload: UserChangeEmail) {
    const { data } = await ProfileEmailPatchRequest(payload)
    if (this.information)
      this.setInformation({
        ...this.information,
        email: data.email,
      })
    return data
  }

  @Action({ rawError: true })
  async updateEmailAndPassword(payload: ChangeEmailAndPassword) {
    const { data } = await ProfileEmailCustomPatchRequest(payload)
    if (this.information) {
      this.setInformation({
        ...this.information,
        email: data.email,
        hasPassword: true,
      })
    }
    return data
  }

  @Action({ rawError: true })
  async fetchRegistratedOrdersInformation () {
    const { data } = await MasterOrdersStatsGetRequest()
    return data
  }

  // ---------------------------- Profile notifications settings ---------------------------- >>
  notificationsSettings: UserNotificationResource = {
    checked: [],
    isDisabledSite: false,
    isDisabledTelegram: false,
    notifications: [],
    telegramChecked: [],
  }

  @Mutation
  setNotificationsSettings (response: UserNotificationResource) {
    this.notificationsSettings = Object.assign({}, response)
  }

  @Action({ rawError: true })
  async fetchNotificationsSettings (params: ProfileNotificationsGetParams) {
    const { data } = await ProfileNotificationsGetRequest(
      params,
    )
    this.setNotificationsSettings(data)
    return data
  }

  @Action({ rawError: true })
  async saveNotificationsSettings (payload: { form: UserNotificationsRequest, params: ProfileNotificationsPatchParams }) {
    const { data } = await ProfileNotificationsPatchRequest(payload.form, payload.params)
    return data
  }

  // ---------------------------- New Year avatars ---------------------------- >>
  avatarList: ThematicAvatarResource[] = []
  avatarSelected: number | null = null

  @Mutation
  setAvatarList(payload: ThematicAvatarResource[]) {
    this.avatarList = [...payload]
  }

  @Mutation
  setAvatarSelected(select: number | null) {
    this.avatarSelected = select
  }

  @Action({ rawError: true })
  async fetchNewYearAvatarList() {
    const { data } = await ProfileThematicAvatarListGetRequest()
    this.setAvatarList(data)
    const find = data.find(avatar => avatar.isSelected)
    if (find)
      this.setAvatarSelected(find.id)
    return data
  }

  @Action({ rawError: true })
  async setNewYearAvatar(id: number | null) {
    const { data } = await ProfileThematicAvatarSetPutRequest({ thematicAvatarId: id })
    this.setAvatarSelected(id)
    return data
  }
}

const ProfileModule = getModule(Profile)

export default ProfileModule
