











































import { reduce, omit, uniqBy } from 'lodash'
import { Component, Mixins, Prop, Ref } from 'vue-property-decorator'

import ChatView from '@/components/views/chat/ChatView.vue'
import Confirmation from '@/components/modals/Confirmation.vue'
import FilesList from '@/components/FilesList.vue'
import Tag from '@/components/_uikit/Tag.vue'
import UserCard from '@/components/cards/UserCard.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
import SupportModule from '@/store/modules/support'
import {
  MediaResource, TaskMessageResource,
  MessageStore, Permission,
  TicketLargeResource,
  TicketStatus,
} from '@/store/types'
import AuthModule from '@/store/modules/auth'
import SystemModule from '@/store/modules/system'
import DictionaryModule from '@/store/modules/dictionary'

const camelCaseKeys = require('camelcase-keys')

@Component({
  components: {
    ChatView,
    Confirmation,
    FilesList,
    Tag,
    UserCard,
  },
})
export default class TicketView extends Mixins(NotifyMixin, PermissionsMixin) {
  @Ref() confirm!: Confirmation

  @Prop({ required: true })
  private ticket!: TicketLargeResource

  private vueScrollOptions = {
    scrollPanel: {
      scrollingX: false,
    },
  }

  private get allAttachments () {
    return uniqBy(reduce(this.ticket.messages, (files: MediaResource[], item: TaskMessageResource ) => {
      if (item.media.length > 0) {
        files.push(...item.media)
      }
      return files
    }, []), 'id')
  }

  private get isMaster () {
    return SystemModule.interface === 'master'
  }

  private get isTicketClosed () {
    return this.ticket.status.value === TicketStatus.CLOSED
  }

  private get isAuthor() {
    return this.ticket.author.id === AuthModule.self?.id
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private sub: any = null

  private mounted () {
    this.sub = this.$centrifuge.newSubscription(`support.${this.ticket.id}`)
    this.sub.on('publication', (ctx: any) => {
      if (ctx.data?.event === 'Support\\NewMessageEvent') {
        const response = omit(ctx.data, ['event', 'socket']) as TaskMessageResource
        SupportModule.addTicketMessage(camelCaseKeys(response, { deep: true }))
      }
    })
    this.sub.subscribe()
  }

  private destroyed() {
    if (this.sub) {
      this.sub.unsubscribe()
      this.$centrifuge.removeSubscription(this.sub)
    }
  }

  private fetchMessageCount() {
    DictionaryModule.fetchMessageCount()
  }

  private handleSendMessage (form: MessageStore) {
    if (this.ticket) {
      SupportModule.sendTicketMessage({
        params: form,
        ticketID: this.ticket.id,
      })
        .then(() => {
          if (!this.isMaster && this.hasPermission(Permission.SUPPORT_ANSWER))
            this.fetchMessageCount()
        })
        .catch(this.notifyError)
    }
  }

  private confirmCloseTicket () {
    this.confirm.open(
      'Закрытие обращения',
      'Вы уверены, что хотите закрыть обращение?',
      {
        buttonConfirmText: 'Закрыть',
      },
    )
      .then(this.handleCloseTicket)
      .catch(() => {return})
  }

  private handleCloseTicket () {
    SupportModule.closeTicket(this.ticket.id)
      .then(() => {
        this.notifySuccess(`Обращение #${this.ticket.id} закрыто`)
        if (!this.isMaster && this.hasPermission(Permission.SUPPORT_ANSWER))
          this.fetchMessageCount()
      })
      .catch(this.notifyError)
  }
}
