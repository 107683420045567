






























import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins, Prop, Ref } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'

import Confirmation from '@/components/modals/Confirmation.vue'
import GroupPackagesProlongationForm from '@/components/forms/course/GroupPackagesProlongationForm.vue'
import GroupPackagesStarterForm from '@/components/forms/course/GroupPackagesStarterForm.vue'
import GroupPackagesTransferForm from '@/components/forms/course/GroupPackagesTransferForm.vue'
import CourseGroupMonthMixin from '@/mixins/manager/CourseGroupMonthMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import ManagerCoursesModule from '@/store/modules/manager/courses'
import { CourseLargeResource, CourseType, GroupLargeResource, GroupPackagesData, GroupPackagesResource, GroupStoreMonthsData, GroupType, MonthShortResource, NameValueResource, PackageShortResource, PackageStoreData } from '@/store/types'
import GroupPackagesPriceForm from '@/components/forms/course/GroupPackagesPriceForm.vue'
import DictionaryModule from '@/store/modules/dictionary'

@Component({
  components: {
    Confirmation,
    GroupPackagesPriceForm,
    GroupPackagesProlongationForm,
    GroupPackagesStarterForm,
    GroupPackagesTransferForm,
    ValidationObserver,
  },
})
export default class GroupPackagesForm extends Mixins(CourseGroupMonthMixin, NotifyMixin) {
  @Ref() confirm!: Confirmation

  @Prop({ required: true })
  private course!: CourseLargeResource

  @Prop({ required: true })
  private group!: GroupLargeResource

  @Prop({ required: true })
  private packages!: GroupPackagesResource

  @Prop({ default: false })
  private readonly!: boolean

  // Есть ли в пакетах перевода или старта продаж пакет с 0 стоиомстью
  private get isFreePackage(): boolean {
    return this.form.starter.some((start: PackageStoreData) => start.price && +start.price === 0) ||
      this.form.transfer.some((transfer: PackageStoreData) => transfer.price && +transfer.price === 0) ||
      this.form.months.some((months: GroupStoreMonthsData) => months.price && +months.price === 0)
  }

  private form: GroupPackagesData = {
    cashboxId: this.cashboxId as number,
    closedForSales: false,
    customMonthPrice: false,
    fakePrice: 0,
    months: [],
    pricePerMonth: '' as unknown as number,
    prolongation: [],
    starter: [],
    transfer: [],
  }

  private starterIDDefaultSelect?: number | null = null

  private get cashboxId() {
    if (DictionaryModule.cashboxes.length > 0) {
      return DictionaryModule.cashboxes[0].value as number
    }
    return null
  }

  private get isMGLite () {
    return this.group.type.value === GroupType.LITE
  }

  private get isSpecial () {
    return this.course.type.value === CourseType.SPECIAL
  }

  // Месяца, используемые в пакетах старта продаж и которые не выбраны для отображения в линейке
  private get monthsInactiveLine() {
    const inactiveMonths = this.form.months.filter(month => !month.isVisibleInShop).map(item => item.id)
    const months = new Set<number>()
    this.form.starter.forEach(item => item.monthIds.forEach(month => months.add(month)))
    return Array.from(months).filter((item: number) => inactiveMonths.includes(item))
  }

  private mounted () {
    this.syncForm()
  }

  private syncForm () {
    this.form = {
      ...this.form,
      cashboxId: this.packages.cashbox ? +this.packages.cashbox.value : this.cashboxId as number,
      closedForSales: this.packages.closedForSales,
      customMonthPrice: this.packages.customMonthPrice,
      fakePrice: this.packages.fakePrice || 0,
      months: this.packages.months.map((month: MonthShortResource) => ({
        id: month.id,
        isVisibleInShop: month.isVisibleInShop,
        price: month.price,
      })),
      pricePerMonth: this.packages.pricePerMonth,
      prolongation: this.packages.prolongation.map((item: PackageShortResource) => ({
        id: item.id,
        monthIds: item.months.map((month: NameValueResource) => +month.value),
        price: item.price,
      })),
      starter: this.packages.starter.map((item: PackageShortResource) => ({
        id: item.id,
        isDefault: Boolean(item.isDefault),
        monthIds: item.months.map((month: NameValueResource) => +month.value),
        price: item.price,
      })),
      transfer: this.packages.transfer.map((item: PackageShortResource) => ({
        id: item.id,
        monthIds: item.months.map((month: NameValueResource) => +month.value),
        packageId: item.package?.id,
        price: item.price,
      })),
    }

    const findDefaultStarterPackage = this.packages.starter.find((_package: PackageShortResource) => _package.isDefault)
    if (findDefaultStarterPackage) {
      this.starterIDDefaultSelect = findDefaultStarterPackage.id
    }
  }

  @Debounce(500)
  @Bind
  private handleSubmit () {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          // Если есть бесплатный пакет и неактивный месяц для линейки
          if (this.isFreePackage && this.monthsInactiveLine.length) {
            this.confirm.open(
              'Бесплатный пакет и месяц(-ы) в периоде обучения',
              `
                <p>Внимание! Стоимость одного из пакетов составляет <span class="text-body-3 secondary--text">0 ₽.</span></p>
                <p>Обрати внимание. В пакетах старта продаж присутствует(-ют) месяц(-ы) <span class="text-body-3 secondary--text">(${this.monthsInactiveLine.map(item => this.getMonthByID(item, this.group.months)).join(', ').toLowerCase()})</span>, не указанный(-ые) в периоде обучения. Продолжить?</p>
              `,
              {
                buttonConfirmText: 'Продолжить',
                skin: 'secondary',
              },
            )
              .then(() => {
                this.savePackages()
              })
              .catch(() => {return})
            return
          }

          // Если есть бесплатный пакет
          if (this.isFreePackage) {
            this.confirm.open(
              'Бесплатный пакет',
              'Внимание! Стоимость одного из пакетов составляет <span class="text-body-3 secondary--text">0 ₽.</span> Продолжить?',
              {
                buttonConfirmText: 'Продолжить',
                skin: 'secondary',
              },
            )
              .then(() => {
                this.savePackages()
              })
              .catch(() => {return})
            return
          }

          // Если есть неактивный месяц для линейки
          if (this.monthsInactiveLine.length && !this.isSpecial) {
            this.confirm.open(
              'Месяц(-ы) в периоде обучения',
              `<p>Обрати внимание. В пакетах старта продаж присутствует(-ют) месяц(-ы) <span class="text-body-3 secondary--text">(${this.monthsInactiveLine.map(item => this.getMonthByID(item, this.group.months)).join(', ').toLowerCase()})</span>, не указанный(-ые) в периоде обучения. Сохранить изменения?</p>`,
              {
                buttonConfirmText: 'Сохранить',
                skin: 'secondary',
              },
            )
              .then(() => {
                this.savePackages()
              })
              .catch(() => {return})
            return
          }
          this.savePackages()
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }

  private savePackages() {
    const form: any = this.$refs.form

    // Проверяем, есть ли у пакетом старта продаж id, если их нет то isDefault должен определяться по индексу массива
    const isNotIdsStarterPackager = this.form.starter.every(start => start.id === undefined)

    ManagerCoursesModule.savePackages({
      courseID: this.course.id,
      params: {
        ...this.form,
        pricePerMonth: this.form.pricePerMonth.toString() === '' ? this.form.fakePrice : this.form.pricePerMonth,
        starter: this.starterIDDefaultSelect !== null && this.starterIDDefaultSelect !== undefined ? this.form.starter.map((start, index) => {
          if ((!isNotIdsStarterPackager && start.id === this.starterIDDefaultSelect) || (isNotIdsStarterPackager && index === this.starterIDDefaultSelect)) {
            return {
              ...start,
              isDefault: true,
            }
          }
          return {
            ...start,
            isDefault: false,
          }
        }) : this.form.starter,
      },
      type: this.group.type.value as GroupType,
    })
      .then(() => {
        this.notifySuccess('Данные группы сохранены')
        requestAnimationFrame(() => (form.reset()))
      })
      .catch(err => {
        if (err.response.status === 409) {
          this.notifyError(err.response.data.message)
          this.form.starter = this.packages.starter.map((item: PackageShortResource) => ({
            id: item.id,
            monthIds: item.months.map((month: NameValueResource) => +month.value),
            price: item.price,
          }))
        } else {
          this.notifyError(err)
        }
      })
  }
}
