






















































import { Component, Prop, Ref } from 'vue-property-decorator'
import loader from '@monaco-editor/loader'
import * as CodeReview from 'monaco-review'
import { ReviewCommentEvent, ReviewManager } from 'monaco-review'
import { ValidationProvider } from 'vee-validate'

// components
import TextInput from '@/components/_uikit/controls/TextInput.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import MentorExercisesModule from '@/store/modules/mentor/exercises'
import {
  CodeExecutionResult,
  EducationAnswerRate, EducationAnswerRateComment,
  EducationLargeTaskCodingQuestionResource,
  EducationLargeTaskResource,
} from '@/store/types'

@Component({
  components: {
    TextInput,
    ValidationProvider,
  },
})
export default class MentorCodingQuestionsView extends NotifyMixin {
  @Ref() monacoEditor!: HTMLElement

  @Prop({ required: true })
  private index!: number

  @Prop({ required: true })
  private question!: EducationLargeTaskCodingQuestionResource

  @Prop({ required: true })
  private task!: EducationLargeTaskResource

  @Prop({ required: true })
  private answersRatePoints!: EducationAnswerRate

  @Prop({ default: () => ([]) })
  private mentorAnswerComments!: EducationAnswerRateComment[]

  @Prop({ default: false })
  private readonly!: boolean

  private get masterGroupID() {
    return this.$route.params.groupID
  }

  private get masterID() {
    return this.$route.params.masterID
  }

  private codeValue = ''
  // количество строк в коде
  private codeLineCounter = 0
  private comments: ReviewCommentEvent[] = []
  private result: CodeExecutionResult | null = null
  private reviewManager: ReviewManager | null = null

  private monacoEditorRef: any = null

  private commentsHeightCache: Record<string, number> = {}

  private mounted() {
    this.codeValue = this.question.userAnswers ? this.question.userAnswers[0].answer : ''
    this.codeLineCounter = this.codeValue.split(/\n/g).length
    loader.config({ paths: { vs: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.52.2/min/vs' } })
    loader.init()
      .then(monacoInstance => {
        this.monacoEditorRef = monacoInstance.editor.create(this.monacoEditor, {
          automaticLayout: true,
          folding: false,
          language: 'python',
          readOnly: true,
          theme: this.$vuetify.theme.dark ? 'vs-dark' : 'vs',
          value: this.codeValue,
        })
        const model = this.monacoEditorRef.getModel()
        model.onDidChangeContent(() => {
          this.codeValue = model.getValue()
        })

        this.reviewManager = CodeReview.createReviewManager(
          this.monacoEditorRef,
          '',
          /* eslint-disable-next-line */
          /* @ts-ignore:next-line */
          this.convertDataToState(this.mentorAnswerComments),
          _comments => {
            if (this.reviewManager) {
              const viewZonesEl: HTMLDivElement | null = document.querySelector('.view-zones')
              let cloneNode: Node | null = null
              if (_comments[_comments.length - 1].type === 'create') {
                const key = _comments[_comments.length - 1].id
                const { viewZoneId } = this.reviewManager.renderStore[key]
                const parentEl: HTMLDivElement | null = document.querySelector(`[monaco-view-zone="${viewZoneId}"]`)
                if (parentEl && viewZonesEl) {
                  cloneNode = parentEl.cloneNode(true)
                  ;(cloneNode as any).style = 'display: block; visibility: hidden;'
                  ;(cloneNode as any).dataset.uuid = key
                  ;(cloneNode as any).dataset.number = '1'
                  viewZonesEl.appendChild(cloneNode)
                  this.commentsHeightCache[`${key}-1`] = (cloneNode as any).offsetHeight
                  this.reviewManager.commentHeightCache = {
                    ...this.reviewManager.commentHeightCache,
                    [`${key}-1`]: this.commentsHeightCache[`${key}-1`],
                  }
                }
              }

              if (_comments[_comments.length - 1].type === 'edit') {
                const key = _comments[_comments.length - 1].targetId ?? _comments[_comments.length - 1].id
                const prevCloneEl: HTMLDivElement | null = document.querySelector(`[data-uuid="${key}"]`)
                if (prevCloneEl) {
                  const number = Number(prevCloneEl.dataset.number) + 1
                  prevCloneEl.remove()
                  const { viewZoneId } = this.reviewManager.renderStore[key]
                  const parentEl: HTMLDivElement | null = document.querySelector(`[monaco-view-zone="${viewZoneId}"]`)

                  if (parentEl && viewZonesEl) {
                    cloneNode = parentEl.cloneNode(true)
                    ;(cloneNode as any).style = 'display: block; visibility: hidden;'
                    ;(cloneNode as any).dataset.uuid = key
                    ;(cloneNode as any).dataset.number = number
                    viewZonesEl.appendChild(cloneNode)
                    this.commentsHeightCache[`${key}-${number}`] = (cloneNode as any).offsetHeight
                    this.reviewManager.commentHeightCache = {
                      ...this.reviewManager.commentHeightCache,
                      [`${key}-${number}`]: this.commentsHeightCache[`${key}-${number}`],
                    }
                  }
                }
              }

              this.reviewManager.loadFromStore(this.reviewManager?.store, this.reviewManager?.events)
            }

            /* eslint-disable-next-line */
            /* @ts-ignore:next-line */
            const comments = this.reviewManager ? this.convertStateToData(Object.values(this.reviewManager.store.comments).map(cs => cs.comment)) : []
            this.$emit('updateComments', { comments, questionID: this.question.id })
          },
          {
            editButtonAddText: 'Ответить',
            editButtonRemoveText: 'Удалить',
            readOnly: this.readonly,
          },
        )
        setTimeout(() => {
          this.renderCloneComments()
          this.renderHeightComments()
        }, 500)
        this.$bus.$on('refreshRateComments', this.refreshComment)
      })
    window.addEventListener('resize', this.resizeHeightComments)
  }

  private destroyed() {
    window.removeEventListener('resize', this.resizeHeightComments)
    this.$bus.$off('refreshRateComments', this.refreshComment as any)
  }

  // Клонирую элемент комментария, чтобы знать его высоту и сделать его скрытым
  private renderCloneComments() {
    if (this.reviewManager) {
      const viewZonesEl: HTMLDivElement | null = document.querySelector('.view-zones')
      for (let key in this.reviewManager.renderStore) {
        const { viewZoneId } = this.reviewManager.renderStore[key]
        const parentEl: HTMLDivElement | null = document.querySelector(`[monaco-view-zone="${viewZoneId}"]`)
        let cloneNode: Node | null = null

        if (parentEl && viewZonesEl) {
          cloneNode = parentEl.cloneNode(true)
          ;(cloneNode as any).style = 'display: block; visibility: hidden;'
          ;(cloneNode as any).dataset.uuid = key
          ;(cloneNode as any).dataset.number = '1'
          viewZonesEl.appendChild(cloneNode)
          this.commentsHeightCache[`${key}-1`] = (cloneNode as any).offsetHeight
        }
      }
    }
  }

  // Переопределение высоты комментариев (при рендеринге компонента или изменении ширины экрана)
  private renderHeightComments() {
    if (this.reviewManager) {
      this.reviewManager.commentHeightCache = {
        ...this.commentsHeightCache,
      }
      this.reviewManager.loadFromStore(this.reviewManager?.store, this.reviewManager?.events)
    }
  }

  private resizeHeightComments() {
    const comments: NodeListOf<HTMLDivElement> = this.monacoEditor.querySelectorAll('.reviewComment[data-uuid]')
    comments.forEach(el => {
      const { number, uuid } = el.dataset
      this.commentsHeightCache[`${uuid}-${number}`] = el.offsetHeight
    })
    this.renderHeightComments()
  }

  private runCode() {
    if (this.task && this.task.formId)
      MentorExercisesModule.executeCodeMaster({
        body: {
          formId: this.task.formId,
          questionId: this.question.id,
        },
        masterGroupID: +this.masterGroupID,
        masterID: +this.masterID,
        taskUUID: this.task.uuid,
      })
        .then(response => {
          this.result = response
        })
        .catch(this.notifyError)
  }

  private convertStateToData(state: ReviewCommentEvent[]): EducationAnswerRateComment[] {
    return state.map((s: any) => ({
      answerLine: s.lineNumber,
      mentorComment: s.text,
      questionId: this.question.id,
      uuid: s.id,
    }))
  }

  private convertDataToState(data: EducationAnswerRateComment[]): ReviewCommentEvent[] {
    return data.map(comment => ({
      createdAt: 0,
      createdBy: '',
      id: comment.uuid,
      lineNumber: comment.answerLine ?? 0,
      text: comment.mentorComment ?? '',
      type: 'create',
    }))
  }

  private refreshComment() {
    /* eslint-disable-next-line */
    /* @ts-ignore:next-line */
    this.reviewManager?.load(this.convertDataToState(this.mentorAnswerComments))
    setTimeout(() => {
      this.renderCloneComments()
      this.renderHeightComments()
    }, 500)
  }
}
