

























































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import AttachmentsWrapper from '@/components/_uikit/AttachmentsWrapper.vue'
import TextAreaInput from '@/components/_uikit/controls/TextAreaInput.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import {
  GET_DEFAULT_PRACTICE_QUESTION_FORM,
  GET_DEFAULT_SPEAKING_QUESTION_FORM,
  GET_DEFAULT_TEST_QUESTION_FORM,
} from '@/components/forms/exercise/constants'
import ExerciseMaterialsMixin from '@/mixins/manager/ExerciseMaterialsMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import DictionaryModule from '@/store/modules/dictionary'
import { NameValueResource, TaskStoreRequest, TaskType } from '@/store/types'
import TextWysiwyg from '@/components/_uikit/controls/TextWysiwyg.vue'
import SwitchInput from '@/components/_uikit/controls/SwitchInput.vue'

@Component({
  components: {
    AttachmentsWrapper,
    Select,
    SwitchInput,
    TextAreaInput,
    TextInput,
    TextWysiwyg,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class ExerciseTaskCommonForm extends Mixins(ExerciseMaterialsMixin, NotifyMixin) {
  @Prop({ required: true })
  private subject!: NameValueResource

  @Prop({ required: true })
  private form!: TaskStoreRequest

  @Prop({ default: false })
  private isCreative!: boolean

  @Prop({ default: false })
  private disabledType!: boolean

  @Prop({ default: false })
  private isTest!: boolean

  @Prop({ default: false })
  private isPractice!: boolean

  @Prop({ default: false })
  private isSpeaking!: boolean

  @Prop({ default: false })
  private isSpendingAt!: boolean

  @Prop({ default: false })
  private isCoding!: boolean

  @Prop({ required: true })
  private autoCheck!: boolean

  private get taskTypes () {
    return DictionaryModule.taskTypes
  }

  private get uploadMaterialsParams () {
    return [{
      name: 'folder',
      value: `${this.subject.name}/Домашняя работа/Материалы`,
    }]
  }

  private get uploadAnswersParams () {
    return [{
      name: 'folder',
      value: `${this.subject.name}/Домашняя работа/Ключи`,
    }]
  }

  private formAutoCheck = false

  private mounted() {
    this.formAutoCheck = this.autoCheck
  }

  private handleChangeTaskType (value: TaskType) {
    if (value === TaskType.TEST || value === TaskType.PRACTICE) {
      this.form.maxPoints = 10
      this.form.managerAnnotation = ''
    }
    if (value === TaskType.TEST) {
      this.form.mediaIds = []
      this.form.answerMediaIds = []
    }

    this.form.test = {
      questions: [GET_DEFAULT_TEST_QUESTION_FORM()],
    }
    this.form.speaking = {
      questions: [GET_DEFAULT_SPEAKING_QUESTION_FORM()],
    }
    this.form.practice = {
      questions: [GET_DEFAULT_PRACTICE_QUESTION_FORM()],
    }
  }

  private handleChangeAutoCheck(value: boolean) {
    this.$bus.$emit('changeAutoCheck', value)
  }

  @Watch('autoCheck')
  private watchAutoCheck() {
    this.formAutoCheck = this.autoCheck
  }
}
