







































import { Component, Prop } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

// components
import MasterEducationModule from '@/store/modules/master/education'
import NewYearHat from '@/components/NewYearHat.vue'
import TextAreaInput from '@/components/_uikit/controls/TextAreaInput.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import { MasterLessonRateRequest } from '@/store/types'

@Component({
  components: {
    NewYearHat,
    TextAreaInput,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class RateLessonModal extends NotifyMixin {
  @Prop({ required: true })
  private visible!: boolean

  private message = 'Мы постоянно улучшаем и совершенствуем материал и программу обучения и поэтому будем рады твоему отзыву или пожеланию.'

  private form: MasterLessonRateRequest = {
    comment: '',
  }

  private get currentMasterId() {
    return MasterEducationModule.currentMasterGroupID
  }

  private options = [
    {
      name: '😓',
      value: 0,
    },
    {
      name: '🙂',
      value: 1,
    },
    {
      name: '😍',
      value: 2,
    },
  ]

  private chosenRating = -1

  private handleClose () {
    this.$emit('update:visible', false)
  }

  private handleChooseRating(rating: number) {
    this.form.rating = rating
    this.chosenRating = rating
  }

  private sendRating() {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          if (this.form.rating === undefined) {
            this.notifyError('Выберите оценку')
            return
          }
          this.$emit('rating', this.form)
          delete this.form.rating
          this.chosenRating = -1 // reset
          this.$emit('update:visible', false)
        }
      })
  }

}
