



































import { orderBy, uniqBy } from 'lodash'
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import vuescroll from 'vuescroll'

import ChatMessageCard from '@/components/cards/ChatMessageCard.vue'
import ChatMessageForm from '@/components/forms/chat/ChatMessageForm.vue'
import { TaskMessageResource, MessageStore } from '@/store/types'

@Component({
  components: {
    ChatMessageCard,
    ChatMessageForm,
  },
})
export default class ChatView extends Vue {
  @Ref() parent!: HTMLDivElement
  @Ref() card !: Vue[]

  @Prop({ default: () => ([]) })
  private messages!: TaskMessageResource []

  @Prop({ required: false })
  private taskUuid!: string

  @Prop({ required: false })
  private masterGroupId!: number

  @Prop({ required: false })
  private masterId!: number

  @Prop({ required: false })
  private exerciseUuid!: string

  @Prop({ default: false })
  private disabled!: boolean

  @Prop({ default: 'Здесь пока нет сообщений' })
  private note!: string

  @Prop({ default: false })
  private isTicket!: boolean

  @Prop({ default: false })
  private recording!: boolean

  @Prop({ default: null })
  private formID!: number | undefined | null

  // редактирование сообщений
  @Prop({ default: false })
  private editMessages!: boolean

  @Prop({ default: 'image/*' })
  private accept!: string

  private scrollOptions = {
    scrollPanel: {
      scrollingX: false,
    },
  }

  private get messagesOrdered () {
    return orderBy(uniqBy(this.messages, 'id'), ['createdAt'])
  }

  private bottomParent = 0

  private syncScroll () {
    const messages = this.$refs.messages as vuescroll
    if (this.messages && (this.messagesOrdered.length || !this.disabled))
      messages.scrollTo({ y: '100%' })
  }

  private handleSendMessage (form: MessageStore) {
    this.$emit('send-message', form)
    this.syncScroll()
  }

  private handleUpdateMessage(form: MessageStore, messageID: number) {
    this.$emit('updateMessage', form, messageID)
  }

  private handleScroll() {
    this.bottomParent = this.parent ? this.parent.getBoundingClientRect().bottom : 0
    this.$bus.$emit('scrollChat', this.bottomParent)
  }

  @Watch('messages', { deep: true })
  private watchMessages(value: TaskMessageResource [], oldValue: TaskMessageResource []) {
    if (value.length && !oldValue.length)
      setTimeout(() => {
        this.syncScroll()
      }, 100)
  }
}
