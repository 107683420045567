












































import { ValidationObserver } from 'vee-validate'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

// components
import ButtonTextIcon from '@/components/_uikit/buttons/ButtonTextIcon.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'
import NewYearHat from '@/components/NewYearHat.vue'
import UploadImageForm from '@/components/forms/editor/UploadImageForm.vue'
import UploadInput from '@/components/_uikit/controls/UploadInput.vue'
// types
import { MediaResource } from '@/store/types'

export interface IImageForm {
  alt: string,
  height: string,
  url: string,
  width: string,
}

@Component({
  components: {
    ButtonTextIcon,
    ModalWrapper,
    NewYearHat,
    UploadImageForm,
    UploadInput,
    ValidationObserver,
  },
})
export default class ImageModal extends Vue {
  @Prop({ required: true })
  private visible!: boolean

  @Prop({ required: true })
  private imageAttr!: any

  private currentItem = 0

  private tabs = [
    'Ссылка',
    'Загрузить файл',
  ]

  private form: IImageForm[] = []

  handleUploadFile(file: MediaResource) {
    const type = file.filename.match(/\.\w+$/m)
    this.form.push({
      alt: type && type[0].toLowerCase() === '.heic' ? 'формат изображения heic' : '',
      height: '',
      url: file.url,
      width: '',
    })
  }

  private handleConfirm () {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          this.$emit('submit', this.form)
          this.handleCancel()
        }
      })
  }

  private handleCancel () {
    const form: any = this.$refs.form
    this.$emit('update:visible', false)
    this.form = []
    form.reset()
  }

  private addImgLink() {
    this.form.push({
      alt: '',
      height: '',
      url: '',
      width: '',
    })
  }

  private handleRemoveImg(index: number) {
    this.form.splice(index, 1)
  }

  private handleHeicAlt(index: number) {
    this.form[index].alt = 'формат изображения heic'
  }

  private handleClearAlt(index: number) {
    this.form[index].alt = ''
  }

  @Watch('visible')
  private watchVisible() {
    if (this.visible && this.imageAttr.url) {
      this.form = [this.imageAttr]
    }
  }
}
