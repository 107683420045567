






























































import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { v4 as uuid } from 'uuid'

import FilesList from '@/components/FilesList.vue'
import Player from '@/components/_uikit/Player.vue'
import Recorder from '@/components/_uikit/Recorder.vue'
import TextAreaInput from '@/components/_uikit/controls/TextAreaInput.vue'
import UploadInput from '@/components/_uikit/controls/UploadInput.vue'
import DetectSafariMixin from '@/mixins/DetectSafariMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import { MediaResource, MessageDraftResource, TaskMessageResource, MessageStore } from '@/store/types'
import MessageModule from '@/store/modules/message'
import AuthModule from '@/store/modules/auth'
import { delayAutosave } from '@/utils/constants'

const camelCaseKeys = require('camelcase-keys')

@Component({
  components: {
    FilesList,
    Player,
    Recorder,
    TextAreaInput,
    UploadInput,
  },
})
export default class ChatMessageForm extends Mixins(DetectSafariMixin, NotifyMixin) {
  @Prop({ default: false })
  private recording!: boolean

  @Prop({ default: null })
  private formID!: number | undefined | null

  private form: MessageStore = {
    mediaIds: [],
    message: '',
  }
  private files: MediaResource[] = []
  private records: MediaResource[] = []
  private isRecording = false
  private isLoadingAudio = false

  private hash: string | null = null
  private previousHash: string | null = null

  // флаг сохранения драфта
  private isSaveDraft = false

  // флаг фокуса на поле ввода
  private isInputFocused = false

  // флаг, сообщающий сокету о том, нужно ли обновлять контент в инпуте
  // нужен для того, чтобы инпут не обновлялся с тем контентом, который пользователь уже отправил
  private isNotUpdateInput = false

  private subscribtionDraft: any = null

  private get isAutoGrow() {
    return !(this.isSafari && (this.$vuetify.breakpoint.width < 768 || this.$vuetify.breakpoint.height < 400))
  }

  private get rows() {
    return this.isAutoGrow ? 3 : this.$vuetify.breakpoint.height < 400 ? 5 : 10
  }

  private get selfID() {
    if (AuthModule.self)
      return AuthModule.self.id
    return null
  }

  // хеш устройства пользователя
  private deviceHash = uuid()

  // флаг о том, что сейчас редактируется сообщение
  private isEdit = false

  // флаг отправки сообщения
  private isSend = false

  // ID редактируемого сообщения
  private messageID: number | null = null

  // временное хранение формы, пока происходит редактирование сообщения
  private tempForm: MessageStore = {
    mediaIds: [],
    message: '',
  }

  // временное хранение файлов, пока происходит редактирование сообщения
  private tempFiles: MediaResource[] = []
  private tempRecords: MediaResource[] = []

  private mounted() {
    // Если есть formID, то нужно подписаться на канал веб-сокета + запросить черновик сообщения
    if (this.formID) {
      this.fetchDraftMessage()
      this.subscribtionDraft = this.$centrifuge.newSubscription(`drafts.message.${this.selfID}.${this.formID}`)
      this.subscribtionDraft.on('publication', ({ data }: any) => {
        if (!this.isEdit) { // не принимать данные по сокетам при редактировании сообщения
          const response: MessageDraftResource = camelCaseKeys(data, { deep: true })
          if (this.isNotUpdateInput) {
            this.isNotUpdateInput = false
            this.hash = null
            this.previousHash = null
          } else if (this.hash === response.previousHash && this.deviceHash !== response.deviceHash) {
            this.hash = response.hash
            this.previousHash = response.previousHash
            this.form.message = response.content as string
          }
        }
      })
      this.subscribtionDraft.subscribe()
    }
    this.$bus.$on('clearTextarea', this.handleClearDraft)
    this.$bus.$on('editMessage', this.editMessage)
    this.$bus.$on('sendMessageEnded', this.handleSendMessageEnded)
  }

  private destroyed() {
    if (this.subscribtionDraft) {
      this.subscribtionDraft.unsubscribe()
      this.$centrifuge.removeSubscription(this.subscribtionDraft)
    }
    this.$bus.$off('clearTextarea', this.handleClearDraft as any)
    this.$bus.$off('editMessage', this.editMessage as any)
    this.$bus.$on('sendMessageEnded', this.handleSendMessageEnded as any)
  }

  private handleClearDraft(data: TaskMessageResource ) {
    if (!this.isInputFocused && data.author.id === this.selfID) {
      this.form.message = ''
      this.hash = null
      this.previousHash = null
    }
  }

  private handleSendMessageEnded() {
    this.isSend = false
  }

  private handleUploadFile (response: MediaResource) {
    this.files.push(response)
    this.form.mediaIds.push(response.id)
  }

  private handleDeleteFile (id: number) {
    this.files = this.files.filter((file: MediaResource) => file.id !== id)
    this.form.mediaIds = this.form.mediaIds.filter((item: number) => item !== id)
  }

  private handleUploadedRecording(file: MediaResource) {
    this.isLoadingAudio = false
    this.records.push(file)
    this.form.mediaIds.push(file.id)
  }

  private handleDeleteRecording(id: number) {
    this.records = this.records.filter((record: MediaResource) => record.id !== id)
    this.form.mediaIds = this.form.mediaIds.filter((item: number) => item !== id)
  }

  private handleBeforeRecording() {
    this.isRecording = true
  }

  private handleAfterRecording() {
    this.isLoadingAudio = true
    this.isRecording = false
  }

  private handleMicFailed() {
    this.isRecording = false
  }

  private fetchDraftMessage() {
    MessageModule.fetchDraftMessage(this.formID as number)
      .then(response => {
        this.hash = response.hash
        this.previousHash = response.previousHash
        if (response.content !== null) {
          this.form.message = response.content
        }
      })
      .catch(this.notifyError)
  }

  private editMessage(payload: { media: MediaResource[], message: string }, messageID: number) {
    // сохраняем текущие данные формы во временные переменные
    this.tempForm = Object.assign(this.form, {})
    this.tempFiles = [...this.files]
    this.tempRecords = [...this.records]

    // заполняем форму редактируемыми данными
    this.form = {
      mediaIds: payload.media.map(file => file.id),
      message: payload.message,
    }
    this.files = [...payload.media.filter(media => media.type !== 'audio')]
    this.records = [...payload.media.filter(media => media.type === 'audio')]

    // меняем флаг о том, что сейчас активировано редактирование
    this.isEdit = true
    this.messageID = messageID
  }

  private cancelEdit() {
    // Возвращаем форму в исходное положение до редактирования
    this.form = Object.assign(this.tempForm, {})
    this.files = [...this.tempFiles]
    this.records = [...this.tempRecords]

    // Сбрасываем временные данные
    this.tempForm = {
      mediaIds: [],
      message: '',
    }
    this.tempFiles = []
    this.tempRecords = []

    // меняем флаг о том, что сейчас редактирование отключено
    this.isEdit = false
    this.messageID = null
  }

  @Debounce(delayAutosave)
  @Bind
  private saveDraftTask(value: string) {
    if (!this.isSaveDraft && !this.isEdit && !this.isSend) {
      this.isSaveDraft = true
      let responseContent = value
      MessageModule.saveDraftMessage({
        body: {
          content: value,
          deviceHash: this.deviceHash,
          previousHash: this.hash,
        },
        formID: this.formID as number,
      })
        .then(response => {
          this.hash = response.hash
          this.previousHash = response.previousHash
          responseContent = response.content as string
        })
        .finally(() => {
          this.isSaveDraft = false
          if (responseContent !== this.form.message.trim() && this.form.message)
            this.saveDraftTask(this.form.message)
        })
    }
  }

  @Debounce(500)
  @Bind
  private handleSendMessage () {
    if (this.form.message.trim()) {
      if (this.isEdit) {
        this.$emit('updateMessage', this.form, this.messageID)
        requestAnimationFrame(this.cancelEdit)
      } else {
        this.$emit('send-message', this.form)
        this.isSend = true
        this.files = []
        this.records = []
        this.isNotUpdateInput = true
        this.form = {
          mediaIds: [],
          message: '',
        }
        requestAnimationFrame(() => {
          this.form = {
            mediaIds: [],
            message: '',
          }
        })
      }
    } else {
      this.notifyError('Введите сообщение')
    }
  }
}
