



























import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator'
import { DateTime } from 'luxon'
import { reduce } from 'lodash'

// components
import Confirmation from '@/components/modals/Confirmation.vue'
import SpeakingQuestionPassingView from '@/components/views/exercise/SpeakingQuestionPassingView.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import AuthModule from '@/store/modules/auth'
import MasterExercisesModule from '@/store/modules/master/exercises'
import {
  EducationLargeExerciseResource,
  EducationLargeTaskDecideRequest,
  EducationLargeTaskDecideSpeakingQuestionAnswerRequest,
  EducationLargeTaskResource,
  EducationLargeTaskSpeakingQuestionResource,
  EducationMasterGroupResource,
  ExerciseStatus,
  SpeakingQuestionType,
} from '@/store/types'
import MasterEducationModule from '@/store/modules/master/education'
// utils
import { formatDate } from '@/utils/functions'

@Component({
  components: {
    Confirmation,
    SpeakingQuestionPassingView,
  },
})
export default class MasterSpeakingTask extends Mixins(NotifyMixin) {
  @Ref() confirm!: Confirmation

  @Prop({ required: true })
  private masterGroup!: EducationMasterGroupResource

  @Prop({ required: true })
  private exercise!: EducationLargeExerciseResource

  @Prop({ required: true })
  private task!: EducationLargeTaskResource

  private get isStatusWait() {
    return this.task.status.value === ExerciseStatus.WAIT || this.task.status.value === ExerciseStatus.IN_PROCESS
  }

  private get answeredQuestions() {
    return reduce(this.form[this.task.uuid].questions, (sum: number, question: any) => {
      if (this.task.speaking) {
        // Ищем вопрос
        const itemQuestion = this.task.speaking.questions.find(subQuestion => subQuestion.id === question.id)
        // Узнаем тип вопроса
        const questionType: SpeakingQuestionType = itemQuestion ? itemQuestion.type : 'audio_single' as SpeakingQuestionType
        // Если тип вопроса === одиночный ответ, то просто смотрим наличие ответа
        if (questionType === 'audio_single') {
          sum += question.answers.length
        } else {
          // Иначе сравнить количество обязательных подвопросов с количеством ответов в подвопросе
          sum += itemQuestion?.questions.filter(item => item.required).length === question.answers.length ? 1 : 0
        }
      }
      return sum
    }, 0)
  }

  private get masterID() {
    return AuthModule.self ? AuthModule.self.id : -1
  }

  private form: { [key: string]: EducationLargeTaskDecideRequest } = {
    [this.task.uuid]: {
      complete: false,
      content: '',
      draftHash: null,
      firstDecide: false,
      questions: [],
    },
  }
  private isCurrentMonthDeadline = formatDate(new Date().toISOString().substr(0, 10), 'MM-yyyy') === formatDate(this.exercise.deadlineAt, 'MM-yyyy')

  private mounted() {
    if (this.task.speaking) {
      this.form[this.task.uuid] = {
        complete: false,
        content: '',
        draftHash: null,
        firstDecide: false,
        questions: this.task.speaking.questions.map((question: EducationLargeTaskSpeakingQuestionResource) => ({
          answers: question.answers.map(item => ({
            id: item.id,
            mediaId: item.media ? item.media.id : null,
            questionsId: item.questionId,
          })) as unknown as EducationLargeTaskDecideSpeakingQuestionAnswerRequest[],
          id: question.id,
          type: question.type,
        })),
      }
    }
  }

  private handleSave () {
    this.handleSubmit(false, () => {
      this.notifySuccess('Ответ сохранен. Не забудь отправить его на проверку')
    })
  }

  private handleSend() {
    this.handleSubmit(true, () => {
      this.notifySuccess(this.exercise.autoCheck ? 'Ответ отправлен. Ключи к заданию находятся во вкладке "Сообщения"' : 'Ответ отправлен на проверку наставнику')
    })
  }

  handleSubmit(complete: boolean, callback?: any) {
    if (complete && (!this.task.speaking || this.answeredQuestions !== this.task.speaking.questions.length)) {
      this.notifyError('На один и более вопрос не был дан ответ.')
    } else {
      if (!this.isCurrentMonthDeadline && (DateTime.fromSQL(this.exercise.verificationAt, { zone: 'Europe/Moscow' }) as any).ts < Date.now()) {
        this.confirm.open(
          'Самопроверка задания',
          'К сожалению, дедлайн проверки прошел, наставник уже не сможет проверить работу. Прикрепляем ключи для самопроверки',
          {
            buttonCancelVisible: false,
            buttonConfirmText: 'Понятно',
            hideDefaultClose: false,
            messageNoMargin: true,
            persistent: true,
            skin: 'secondary',
          },
        )
          .then(() => {
            this.submit(complete, callback)
          })
          .catch(() => {return})
      } else {
        this.submit(complete, callback)
      }
    }
  }

  private submit(complete: boolean, callback?: any) {
    MasterExercisesModule.executeExercisePracticeAndTestTask({
      exerciseUUID: this.task.exerciseUuid,
      masterGroupID: this.masterGroup.id,
      options: {
        masterId: complete ? this.masterID : undefined,
      },
      params: {
        ...this.form[this.task.uuid],
        complete,
        content: '',
      },
      taskUUID: this.task.uuid,
    })
      .then(() => {
        if (typeof callback === 'function') {
          callback()
        }
        this.$emit('submit', complete)

        // После отправки дз обновляем список дз на странице /master/exercises
        if (MasterEducationModule.currentMasterGroup && complete) {
          MasterExercisesModule.setExerciseContainersFilterMonth(this.exercise.monthId)
          MasterExercisesModule.fetchExerciseContainers({
            masterGroupID: MasterEducationModule.currentMasterGroup.id,
            params: MasterExercisesModule.exerciseContainersFilter,
          })
            .catch(this.notifyError)
        }
      })
      .catch(this.notifyError)
  }

  @Watch('task')
  private watchTask(value: EducationLargeTaskResource) {
    if (value.speaking) {
      if (!this.form[value.uuid]) {
        this.form[value.uuid] = {
          ...this.form[value.uuid],
          questions: value.speaking.questions.map((question: EducationLargeTaskSpeakingQuestionResource) => ({
            answers: question.answers.map(item => ({
              id: item.id,
              mediaId: item.media ? item.media.id : null,
              questionsId: item.questionId,
            })) as unknown as EducationLargeTaskDecideSpeakingQuestionAnswerRequest[],
            id: question.id,
            type: question.type,
          })),
        }
      }
    }
  }
}
