














































































import { Component, Mixins, Watch } from 'vue-property-decorator'
import { omit } from 'lodash'
import Cookies from 'js-cookie'
import camelCaseKeys from 'camelcase-keys'
import { vueTelegramLogin } from 'vue-telegram-login'

// components
import CourseProlongationModal from '@/components/modals/courses/CourseProlongationModal.vue'
import MainTourModal from '@/components/modals/MainTourModal.vue'
import MainTourCompleteModal from '@/components/modals/MainTourCompleteModal.vue'
import NewsModal from '@/components/modals/NewsModal.vue'
import NewYearAvatarModal from '@/components/modals/NewYearAvatarModal.vue'
import ProgressNewAchievementModal from '@/components/modals/progress/ProgressNewAchievementModal.vue'
import ProgressNewLevelModal from '@/components/modals/progress/ProgressNewLevelModal.vue'
// mixins
import DiscountModal from '@/components/modals/DiscountModal.vue'
import MasterProgressToastMixin from '@/mixins/master/progress/MasterProgressToastMixin'
import TelegramMixin from '@/mixins/TelegramMixin'
import WindowIsFocusMixin from '@/mixins/WindowIsFocusMixin'
// store
import AuthModule from '@/store/modules/auth'
import MasterEducationModule from '@/store/modules/master/education'
import MasterProgressAchievementsModule from '@/store/modules/master/progress_achievements'
import NotificationModule from '@/store/modules/notification'
import ProfileModule from '@/store/modules/profile'
import SystemModule from '@/store/modules/system'
// types
import { NewsShortResource, SaleNotificationResource } from '@/store/types'
import { AchievementResource, UserRankWithInfoResource } from '@/store/types/schema.progress'
// utils
import { isNewYear } from '@/utils/constants'

export type NewsRequestResource = NewsShortResource & { show?: boolean }

@Component({
  components: {
    DiscountModal,
    CourseProlongationModal,
    NewsModal,
    NewYearAvatarModal,
    ProgressNewAchievementModal,
    ProgressNewLevelModal,
    MainTourModal,
    MainTourCompleteModal,
    vueTelegramLogin,
  },
})
export default class GlobalModals extends Mixins(WindowIsFocusMixin, MasterProgressToastMixin, TelegramMixin) {
  private get isMaster(): boolean {
    return SystemModule.interface === 'master'
  }

  // Доступен ли туториал для автоматического запуска
  private get masterGroups() {
    return MasterEducationModule.masterGroups
  }

  private get isPassedTour() {
    return AuthModule.self?.tutorialPassed
  }

  private get isCurrentCourse() {
    return MasterEducationModule.currentMasterGroupCourseID
  }

  private get userID() {
    return AuthModule.self?.id
  }

  private get isUserNewYearModalPassed() {
    return Boolean(AuthModule.self?.newYearModalWindowPassed)
  }

  private get socials() {
    return ProfileModule.information?.socials ?? []
  }

  /**
   * Интерфейс мастера
   * Наличие купленных курсов
   * Туториал ранее не был пройден
   * Наличие доступного купленного курса
   */
  private get isEnableTour() {
    return Boolean(this.isMaster && this.masterGroups.length && !this.isPassedTour && this.isCurrentCourse)
  }

  private get isNewYear() {
    return isNewYear
  }

  private visibleTourModal = false
  private visibleTourModalComplete = false
  private visibleProgressNewLevelModal = false
  private visibleProgressNewAchievementModal = false // вернуть
  private visibleDiscountsModal = false
  private visibleNewYearAvatarModal = !this.isUserNewYearModalPassed && this.isNewYear

  private newLevel: UserRankWithInfoResource | null = null

  private currentNewAchievement: AchievementResource | null = null

  // Продление купленного курса
  private showCourseProlongationModal = false
  private selectedProlongationCourseID: number | null = null

  private handleCourseProlongation (courseID: number) {
    this.selectedProlongationCourseID = courseID
    this.showCourseProlongationModal = true
  }

  private handlecloseProlongationModal() {
    this.selectedProlongationCourseID = null
    this.showCourseProlongationModal = false
  }

  private news: NewsRequestResource[] = []
  private discounts: SaleNotificationResource[] = []
  private uuid = ''

  private sub: any = null

  // Инициализация
  private mounted () {
    this.sub = this.$centrifuge.newSubscription(`gamification.user.${this.userID}`)
    this.sub.on('publication', (ctx: any) => {
      //  Если вкладка активна показываем модалку, если нет записываем её в локал сторейдж
      if (ctx.data?.event === 'NewUserRank' && this.isMaster) {
        const data = camelCaseKeys(ctx.data, { deep: true })
        const newLevel = omit(data, ['event', 'socket']) as UserRankWithInfoResource
        if (this.isWindowInFocus) {
          this.newLevel = newLevel
          this.visibleProgressNewLevelModal = true
        } else {
          localStorage.setItem('last_new_level', JSON.stringify(newLevel))
        }
      }

      //  Если вкладка активна показываем тост, если нет добавляем его в буффер
      if (ctx.data?.event === 'NewUserAchievement' && this.isMaster) {
        const data = camelCaseKeys(ctx.data, { deep: true })
        const newAchievement = omit(data, ['event', 'socket']) as AchievementResource

        if (this.isWindowInFocus) {
          this.toastNewAchievement(newAchievement, this.openProgressNewAchievementModal.bind(this))
        } else {
          MasterProgressAchievementsModule.addToastToBuffer(newAchievement)
        }
      }
    })
    this.sub.subscribe()

    this.$bus.$on('show-prolongation-modal', this.handleCourseProlongation)

    // Событие вызывается, когда приходит новость по веб-сокетам
    this.$bus.$on('openNews', this.openNews)

    // Событие вызывается, когда новость о скидках приходит по веб-сокетам
    this.$bus.$on('openSalesNews', this.openSalesNews)

    // Событие вызывается, когда в уведомлениях нажимаешь "Открыть полностью" у новости
    this.$bus.$on('fetchNewsItem', this.fetchNewsItem)

    // Событие вызывается, когда мастер завершил прохождение туториала в первый раз
    this.$bus.$on('completeMainTour', this.completeMainTour)

    // Событие вызывается, когда пользователь нажимает "Я посмотрел урок" и у него апнулся лвл
    // this.$bus.$on('new-level', (response: UserRankWithInfoResource) => {
    //   this.newLevel = response
    //   this.visibleProgressNewLevelModal = true
    // })

    // Вешаем слушатели для проверки в фокусе ли окно и прокидываем необходимые коллбэки
    this.addListenerForCheckWindowFocus({
      onFocus: () => {
        this.showBufferedToasts(this.openProgressNewAchievementModal.bind(this))
        this.showLocalStorageModal()
      },
    })

    if (!Cookies.get('news')) {
      this.fetchNews()
      Cookies.set('news', true)
    }

    if (!Cookies.get('sales')) {
      this.fetchSalesNews()
      Cookies.set('sales', true)
    }
  }

  private destroyed() {
    if (this.sub) {
      this.sub.unsubscribe()
      this.$centrifuge.removeSubscription(this.sub)
    }
  }

  private showLocalStorageModal () {
    const newLevel = localStorage.getItem('last_new_level')
    localStorage.removeItem('last_new_level')
    if (newLevel) {
      this.newLevel = JSON.parse(newLevel)
      this.visibleProgressNewLevelModal = true
    }
  }

  private fetchNews() {
    NotificationModule.fetchNews()
      .then(response => {
        this.news = response.map((news: NewsRequestResource) => {
          return {
            ...news,
            show: true,
          }
        })
      })
  }

  private closeNews(index: number) {
    this.news = this.news.filter((news: NewsRequestResource, idx: number) => idx !== index)
  }

  private fetchSalesNews() {
    NotificationModule.fetchSalesNews()
  }

  private openNews(news: NewsShortResource) {
    const findNews = this.news.find(item => item.id === news.id)
    if (!findNews) {
      this.news.push({
        ...news,
        show: true,
      })
    }
  }

  private openSalesNews(payload: { sales: SaleNotificationResource[], uuid: string }) {
    this.discounts = [...payload.sales]
    this.uuid = payload.uuid
    this.visibleDiscountsModal = true
  }

  private fetchNewsItem(id: number) {
    NotificationModule.fetchNewsItem(id)
      .then(response => {
        this.openNews(response)
      })
  }

  private closeProgressNewLevelModal() {
    this.visibleProgressNewLevelModal = false
  }

  private openProgressNewAchievementModal (item: AchievementResource) {
    this.currentNewAchievement = item
    this.visibleProgressNewAchievementModal = true
  }

  private closeProgressNewAchievementModal() {
    this.visibleProgressNewAchievementModal = false
  }

  private closeDiscountsModal() {
    this.visibleDiscountsModal = false
  }

  private tourStart() {
    this.visibleTourModal = false
    // Нужно поменять активную мастер-группу, если они не совпадают, иначе необязательно что 1-я группа может быть в доступном курсе
    if (this.isCurrentCourse && this.masterGroups.length && this.isCurrentCourse !== this.masterGroups[0].id)
      MasterEducationModule.setCurrentMasterGroupID(this.isCurrentCourse)
    if (this.$route.name === 'master') {
      this.$bus.$emit('changeCurrentIndexCourse', { fetchDashboard: true, masterGroupID: MasterEducationModule.currentMasterGroupID })
      this.$bus.$emit('startTour')
      return
    }
    this.$router.push({ name: 'master' })
      .then(() => {
        this.$bus.$emit('startTour')
      })
  }

  private completeMainTour() {
    this.visibleTourModalComplete = true
  }

  @Watch('isEnableTour')
  private watchIsEnableTour(value: boolean) {
    if (value) {
      this.visibleTourModal = true
    }
  }
}
