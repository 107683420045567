




























import { isEqual, omit, reduce } from 'lodash'
import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import * as Sentry from '@sentry/vue'
import { Debounce } from 'lodash-decorators'
import camelCaseKeys from 'camelcase-keys'
import { v4 as uuid } from 'uuid'

import Confirmation from '@/components/modals/Confirmation.vue'
import TestQuestionPassingView from '@/components/views/exercise/TestQuestionPassingView.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import { IPracticeAndTestTaskForm } from '@/store/types/forms'
import AuthModule from '@/store/modules/auth'
import MasterExercisesModule from '@/store/modules/master/exercises'
import {
  EducationLargeExerciseResource,
  EducationLargeTaskDecideQuestionAnswerRequest,
  EducationLargeTaskDecideQuestionRequest,
  EducationLargeTaskResource,
  EducationLargeTaskTestQuestionResource,
  EducationMasterGroupResource,
  ExerciseStatus,
  TaskQuestionType,
  TaskType,
} from '@/store/types'
import MasterEducationModule from '@/store/modules/master/education'
import { delayAutosave, updateTaskFormMinutes, updateTaskMinutes } from '@/utils/constants'
import { findObjectDifferences } from '@/utils/functions'

@Component({
  components: {
    Confirmation,
    TestQuestionPassingView,
    ValidationObserver,
  },
})
export default class MasterTestPassing extends Mixins(NotifyMixin) {
  @Ref() confirm!: Confirmation

  @Prop({ required: true })
  private masterGroup!: EducationMasterGroupResource

  @Prop({ required: true })
  private task!: EducationLargeTaskResource

  @Prop({ required: true })
  private exercise!: EducationLargeExerciseResource

  @Prop({ default: false })
  private isRePassing!: boolean

  private form: IPracticeAndTestTaskForm = {
    complete: false,
    content: '',
    draftHash: this.task.draftHash || null,
    questions: [],
  }

  private subscribtionDraft: any = null
  private subscribtionTaskStatusUpdate: any = null

  // Кол-во вопросов с типом "Без ответа"
  private get questionsNoAnswerTypeCount() {
    if (this.task.test) {
      return this.task.test.questions.filter(question => question.type.value === TaskQuestionType.NO_ANSWER).length
    }
    return 0
  }

  // Вопросы на соответствие
  private get rationQuestions() {
    const ratio: { [key: number]: number } = {}
    if (this.task.test) {
      this.task.test.questions.filter(question => question.type.value === TaskQuestionType.RATIO).forEach(question => {
        ratio[question.id] = question.answers.length
      })
    }
    return ratio
  }

  private get answeredQuestions () {
    return reduce(this.form.questions, (sum: number, question: EducationLargeTaskDecideQuestionRequest) => {
      if (question.answers.length) {
        const answersCount = reduce(question.answers, (answerSum: number, answer: EducationLargeTaskDecideQuestionAnswerRequest) => {
          if (answer.value || answer.sequenceId) {
            answerSum += 1
          }
          return answerSum
        }, this.questionsNoAnswerTypeCount)
        if (answersCount - this.questionsNoAnswerTypeCount) {
          if (!(this.rationQuestions[question.id] && this.rationQuestions[question.id] !== answersCount - this.questionsNoAnswerTypeCount)) {
            sum += 1
          }
        }
      }
      return sum
    }, this.questionsNoAnswerTypeCount)
  }

  private get isLargeView() {
    return !(this.$vuetify.breakpoint.name === 'xs')
  }

  private get isStatusWait() {
    const { value } = this.task.status
    return value === ExerciseStatus.WAIT || value === ExerciseStatus.IN_PROCESS
  }

  private get selfID() {
    if (AuthModule.self)
      return AuthModule.self.id
    return null
  }

  private get masterID() {
    return AuthModule.self ? AuthModule.self.id : -1
  }

  private get formString() {
    return JSON.stringify(this.form.questions)
  }

  private get formQuestionsStringify() {
    return JSON.stringify(this.form.questions)
  }

  private get hash() {
    return MasterExercisesModule.task?.draftHash
  }

  private get questionListTypeText() {
    const question: Record<number, number> = {}
    this.task.test?.questions.forEach((q: EducationLargeTaskTestQuestionResource) => {
      if (q.type.value === TaskQuestionType.TEXT)
        question[q.id] = q.position
    })
    return question
  }

  // Валидность текстовых вопрос на содержание символов одной раскладки
  private get isInvalidKeyboardLayout() {
    const questions: number[] = []
    const cyrillic = new RegExp('[а-я]+', 'i')
    const latin = new RegExp('[a-z]+', 'i')
    this.form.questions.forEach(q => {
      if (this.questionListTypeText[q.id] && q.answers.length) {
        if (cyrillic.test(q.answers[0].value?.toString() ?? '') && latin.test(q.answers[0].value?.toString() ?? '')) {
          questions.push(this.questionListTypeText[q.id])
        }
      }
    })
    return questions
  }

  private isLoading = true

  // Последнее значение контента
  // Нужно для сравнения текущего контента, с последним отправленным на бэк, чтобы не слать повторно одно и тоже
  private lastContentValue = JSON.stringify(this.form.questions)

  // Флаг отправки контента автосохранения
  private isLoadingForm = false

  // Есть ли соединение с интернетом
  private isOnline = navigator.onLine

  // Таймштамп потери интернета
  private timestampLossInternet = 0

  // Заблокировано ли прохождение теста
  private isDisabled = false

  // Флаг для определения, было вызвано событие pagehide или нет
  private terminatingEventSent = false

  // Флаг актуальности контента
  private isRelevantContent = true

  // Флаг нахождения вкладки браузера в фокусе
  private isFocusedWindow = document.hasFocus()

  // Таймштамп ухода с вкладки
  private blurFocusTimestamp = 0

  // Таймштамп обновления формы теста
  private updateFormTimestamp = Date.now()

  // хеш устройства пользователя
  private deviceHash = uuid()

  private updateFormOnSocket(questions: EducationLargeTaskDecideQuestionRequest[], data: any) {
    this.lastContentValue = JSON.stringify(questions)
    this.form.questions = questions
    Sentry.addBreadcrumb({
      category: 'message',
      data: {
        form: JSON.stringify(questions),
        userID: this.selfID,
      },
      level: 'info',
      message: 'Изменение формы по сокетам',
    })
    this.form.draftHash = data.hash
    MasterExercisesModule.setHashTask(data.hash)
    // Обновляем отображение формы теста
    setTimeout(() => {
      this.$bus.$emit('updateTestFormOnSocket')
      Sentry.addBreadcrumb({
        category: 'message',
        data: {
          userID: this.selfID,
        },
        level: 'info',
        message: 'Обновляем отображение формы теста',
      })
    })
  }

  private mounted () {
    window.addEventListener('online', this.connect)
    window.addEventListener('offline', this.disconnect)
    window.addEventListener('pagehide', this.handlePageHide)
    document.addEventListener('visibilitychange', this.handlePageHide)
    window.addEventListener('focus', this.handleWindowFocus)
    window.addEventListener('blur', this.handleWindowBlur)

    if (this.task.test) {
      this.form.questions = this.task.test.questions.map((question: EducationLargeTaskTestQuestionResource) => ({
        answers: this.isRePassing ? [] : question.userAnswers.map(answer => {
          const obj: any = {}
          if (answer.sequenceId) {
            obj.sequenceId = answer.sequenceId
          }
          obj.value = answer.value
          return obj
        }),
        id: question.id,
      }))
      this.lastContentValue = JSON.stringify(this.form.questions)
      setTimeout(() => this.isLoading = false, 2500)
    } else {
      this.isLoading = false
    }
    this.$bus.$on('deleteAnswer', this.deleteAnswer)

    if (this.isStatusWait) {
      this.subscribtionDraft = this.$centrifuge.newSubscription(`drafts.task.${this.selfID}.${this.task.uuid}.${this.masterID}`)
      this.subscribtionDraft.on('publication', ({ data }: any) => {
        const questions = camelCaseKeys(data.questions, { deep: true })
        if (this.isRelevantContent && this.deviceHash !== data.device_hash) {
          if ((!this.isFocusedWindow && this.hash !== data.hash) || (Date.now() - this.updateFormTimestamp > updateTaskFormMinutes * 60000)) {
            this.updateFormOnSocket(questions, data)
          } else if (!this.isFocusedWindow && this.hash !== data.hash && this.hash !== data.previous_hash) {
            this.showModalError('socket')
          }
        }
      })
      this.subscribtionDraft.subscribe()

      this.subscribtionTaskStatusUpdate = this.$centrifuge.newSubscription(`task.${this.selfID}.${this.task.uuid}.${this.masterID}`)
      this.subscribtionTaskStatusUpdate.on('publication', ({ data }: any) => {
        const { task } = camelCaseKeys(omit(data, ['event', 'socket']), { deep: true }) as { task: EducationLargeTaskResource }
        if (!this.timestampLossInternet || task.status.value !== ExerciseStatus.WAIT || (this.timestampLossInternet && this.timestampLossInternet < task.dataTimestamp)) {
          this.$emit('updateStatusTask', task)
          if (task.forms[0].isWorkOnMistakesRequired && task.forms.length === 1 && (task.forms[0].correct !== task.forms[0].questions || task.forms[0].rate !== task.maxPoints) && task.forms[0].completedAt && !this.exercise.autoCheck && task.maxPoints) {
            MasterExercisesModule.fetchMistakes({ formID: task.forms[0].id, masterGroupID: this.masterGroup.id })
            // Если при обновлении статуса теста по сокетам нужно показать модалку о прохождении РНО, то раскомментировать
            // this.$bus.$emit('mistakesModal')
          }
        }
      })
      this.subscribtionTaskStatusUpdate.subscribe()
    }
  }

  private destroyed() {
    window.removeEventListener('online', this.connect)
    window.removeEventListener('offline', this.disconnect)
    window.removeEventListener('pagehide', this.handlePageHide)
    document.removeEventListener('visibilitychange', this.handlePageHide)
    window.removeEventListener('focus', this.handleWindowFocus)
    window.removeEventListener('blur', this.handleWindowBlur)
    this.$bus.$off('deleteAnswer', this.deleteAnswer as any)
    if (this.subscribtionDraft) {
      this.subscribtionDraft.unsubscribe()
      this.$centrifuge.removeSubscription(this.subscribtionDraft)
    }
    if (this.subscribtionTaskStatusUpdate) {
      this.subscribtionTaskStatusUpdate.unsubscribe()
      this.$centrifuge.removeSubscription(this.subscribtionTaskStatusUpdate)
    }
    this.$centrifuge.off('connected', this.connectSocket)
  }

  private handleSubmit (complete: boolean, callback?: any) {
    const form: any = this.$refs.form

    if (!complete) {
      MasterExercisesModule.executeExercisePracticeAndTestTask({
        exerciseUUID: this.task.exerciseUuid,
        masterGroupID: this.masterGroup.id,
        params: {
          ...this.form,
          complete,
          content: '',
          firstDecide: false,
        },
        taskUUID: this.task.uuid,
      })
        .then(() => {
          if (typeof callback === 'function') {
            callback()
          }
          this.$bus.$emit('submitTest', complete)
        })
        .catch(this.notifyError)
    } else {
      form.validate()
        .then(async (result: boolean) => {
          if (result) {

            // проверяем, все ли вопросы на соответствие заполнены корректно
            // если хотя бы в одном не хватает ответа, то отправка произойти не должна
            const incorrectSequenceQuestions: number[] = []
            this.form.questions.forEach((question, index) => {
              if (this.task.test?.questions[index] && this.task.test?.questions[index].type.value === TaskQuestionType.RATIO &&
                this.task.test?.questions[index].answers.length !== question.answers.length) {
                incorrectSequenceQuestions.push(index)
              }
            })

            if (incorrectSequenceQuestions.length) {
              // Sentry.captureMessage('У пользователя слетел ответ в вопросе на соответствие при отправке теста')
              this.notifyError(`Недопустимое значение при вводе ответа в вопросах: ${incorrectSequenceQuestions.map((q: number) => `№${q + 1}`).join(', ')}`)
              return
            }

            MasterExercisesModule.executeExercisePracticeAndTestTask({
              exerciseUUID: this.task.exerciseUuid,
              masterGroupID: this.masterGroup.id,
              options: {
                masterId: this.masterID,
              },
              params: {
                ...this.form,
                complete,
                content: '',
                firstDecide: this.isStatusWait,
              },
              taskUUID: this.task.uuid,
            })
              .then((response) => {
                if (!this.isRePassing)
                  Sentry.captureMessage('Мастер успешно отправил тест на проверку')

                if (typeof callback === 'function') {
                  callback()
                }
                this.$bus.$emit('submitTest', complete)

                // После отправки дз обновляем список дз на странице /master/exercises
                if (MasterEducationModule.currentMasterGroup && complete) {
                  MasterExercisesModule.setExerciseContainersFilterMonth(this.exercise.monthId)
                  MasterExercisesModule.fetchExerciseContainers({
                    masterGroupID: MasterEducationModule.currentMasterGroup.id,
                    params: MasterExercisesModule.exerciseContainersFilter,
                  })
                    .catch(this.notifyError)
                }

                if (response.forms[0].isWorkOnMistakesRequired && response.forms.length === 1 && (response.forms[0].correct !== response.forms[0].questions || response.forms[0].rate !== response.maxPoints) && response.forms[0].completedAt && !this.exercise.autoCheck && response.maxPoints) {
                  MasterExercisesModule.fetchMistakes({ formID: response.forms[0].id, masterGroupID: this.masterGroup.id })
                  this.$bus.$emit('mistakesModal')
                }
              })
              .catch((err) => {
                if (err.response.status === 422) {
                  this.$bus.$emit('submitTest', complete)
                  this.$bus.$emit('updateTask') // В случае повторной отправки теста и получения ошибки, что этот тест уже сдан обновляем его, чтобы пользователь понял, что тест уже сдан

                  // После отправки дз обновляем список дз на странице /master/exercises
                  if (MasterEducationModule.currentMasterGroup && complete) {
                    MasterExercisesModule.setExerciseContainersFilterMonth(this.exercise.monthId)
                    MasterExercisesModule.fetchExerciseContainers({
                      masterGroupID: MasterEducationModule.currentMasterGroup.id,
                      params: MasterExercisesModule.exerciseContainersFilter,
                    })
                      .catch(this.notifyError)
                  }
                }
                this.notifyError(err)
              })
          } else {
            const errors: string[] = []
            Object.keys(form.errors).forEach(key => {
              if (form.errors[key].length) {
                errors.push(key)
              }
            })
            const questions: string[] = Array.from(new Set(errors.map(value => value.split('_')[1])))
            this.notifyError(`${questions.length ? `Неотвеченные вопросы: ${questions.map(q => `№${q}`).join(', ')}` : 'Ты не дал(-а) ответы на все вопросы'}`)
          }
        })
    }
  }

  private handleSave () {
    this.handleSubmit(false, () => {
      this.notifySuccess('Ответы сохранены. Не забудь отправить тест на проверку')
    })
  }

  private handleSend () {
    if (this.isInvalidKeyboardLayout.length) {
      this.confirm.open(
        'Ой! Кажется, в одном из ответов рядом стоят кириллические и латинские символы.',
        `Все слова должны состоять из символов одного языка (русский или английский). Вернись к заданию(-ям) ${this.isInvalidKeyboardLayout.map(q => `<span class="primary--text">${q}</span>`).join(', ')} и проверь свой ответ. Пример ошибки: <span class="secondary--text">SМИТАP</span>.`,
        {
          buttonCancelText: 'Исправить',
          buttonConfirmText: 'Пропустить',
        },
      )
        .then(() => {
          this.handleSubmit(true, () => {
            this.notifySuccess('Тест отправлен')
          })
        })
        .catch(() => {return})
    } else {
      this.handleSubmit(true, () => {
        this.notifySuccess('Тест отправлен')
      })
    }
  }

  private deleteAnswer(payload: { answerID: number, questionIndex: number }) {
    /*Sentry.addBreadcrumb({
      category: 'message',
      data: payload,
      level: 'info',
      message: 'Удаление ответа из вопроса теста',
    })*/
    this.form.questions[payload.questionIndex].answers = this.form.questions[payload.questionIndex].answers.filter(answer => answer.value !== payload.answerID)
  }

  private updateHashDraft() {
    MasterExercisesModule.fetchDraftTask({
      masterId: this.masterID,
      taskType: TaskType.TEST,
      taskUuid: this.task.uuid,
    })
  }

  private async saveDraftTask() {
    this.isLoadingForm = true
    await this.saveDraft()
      .then((response: any) => {
        this.isLoadingForm = false
        const _isEqual = isEqual(this.form.questions, response.questions)
        if (!_isEqual && this.deviceHash === response.deviceHash) {
          this.saveDraftTask()
        }
      })
      .catch(() => {
        this.isLoadingForm = false
      })
  }

  private saveDraft() {
    return new Promise((resolve, reject) => {
      MasterExercisesModule.saveDraftTask({
        body: {
          content: '',
          deviceHash: this.deviceHash,
          previousHash: this.task.draftHash || null,
          questions: this.form.questions,
        },
        masterId: this.masterID,
        taskType: TaskType.TEST,
        taskUuid: this.task.uuid,
      })
        .then(response => {
          this.lastContentValue = JSON.stringify(response.data.questions)
          this.form.draftHash = response.data.hash
          resolve(response.data)
        })
        .catch(err => {
          if (err.code === 'ECONNABORTED') {
            Sentry.addBreadcrumb({
              category: 'message',
              data: {
                exerciseUUID: this.exercise.uuid,
                masterID: this.masterID,
                taskUUID: this.task.uuid,
              },
              level: 'info',
              message: 'Данные по запросу',
            })
            Sentry.captureMessage('Запрос отменен по таймауту')
            this.notifyError('Изменения не сохранились. Продолжайте работу, система повторит попытку сохранения данных.')
            this.updateHashDraft()
          } else {
            // fix sentry issue
            // https://sn.atwinta.online/organizations/atwinta/issues/21708/?project=22&query=&referrer=project-issue-stream
            if (err && err.response && err.response.status === 409) {
              this.showModalError('save')
              this.notifyError(err.response ? err.response.data.message : 'Содержимое теста изменено с другого устройства или вкладки.')
            } else {
              // Sentry.captureMessage(`При отправке драфта (мастер, тест) произошла ошибка: ${err}`)
              this.notifyError(err)
            }
          }
          reject(err)
        })
    })
  }

  private connect() {
    this.$centrifuge.on('connected', this.connectSocket)
  }

  private disconnect() {
    this.$centrifuge.off('connected', this.connectSocket)
    this.timestampLossInternet = Number(Date.now().toString().slice(0, -3))
    this.isOnline = false
    this.isDisabled = true
    this.notifyInfo('Потеряно соединение с интернетом')
  }

  private connectSocket() {
    this.notifyInfo('Соединение с интернетом восстановлено')
    this.isDisabled = false
    this.isOnline = true
  }

  private handlePageHide({ type }: Event) {
    if (type === 'visibilitychange' && document.visibilityState === 'visible') {
      this.terminatingEventSent = false
    }

    if (this.lastContentValue !== JSON.stringify(this.form.questions)) {
      if (this.terminatingEventSent) return
      if (type === 'pagehide') {
        this.terminatingEventSent = true
        if (this.isRelevantContent)
          this.saveDraftTask()
        return
      }
      if (type === 'visibilitychange' && document.visibilityState === 'hidden') {
        this.terminatingEventSent = true
        if (this.isRelevantContent)
          this.saveDraftTask()
      }
    }
  }

  private handleWindowFocus() {
    this.isFocusedWindow = true
    if (this.blurFocusTimestamp && Date.now() - this.blurFocusTimestamp > updateTaskMinutes * 60000) {
      window.location.reload()
    }
  }

  private handleWindowBlur() {
    this.isFocusedWindow = false
    this.blurFocusTimestamp = Date.now()
  }

  private showModalError(from: 'save' | 'socket') {
    Sentry.captureMessage(`Данные пользователя (мастера) устарели (в тесте) по причине: ${from === 'socket' ? 'обновление сокетов' : 'отправка сохранения'}`)
    this.isRelevantContent = false
    this.confirm && this.confirm.open(
      'Данные устарели',
      'Данные теста устарели, обновите страницу.',
      {
        buttonCancelVisible: false,
        buttonConfirmText: 'Обновить',
        hideDefaultClose: true,
        persistent: true,
      },
    )
      .then(() => window.location.reload())
      .catch(() => {return})
  }

  private getRelatedQuestion(uuid: string | null): EducationLargeTaskTestQuestionResource | null {
    if (uuid) {
      return this.task.test?.questions.find((question: EducationLargeTaskTestQuestionResource) => question.uuid === uuid) || null
    }
    return null
  }

  @Debounce(delayAutosave)
  @Watch('formString')
  private watchFormString(value: string) {
    this.updateFormTimestamp = Date.now()
    if (!this.isLoading && this.lastContentValue !== value) {
      if (!this.isLoadingForm) {
        this.saveDraftTask()
      }
    }
  }

  @Watch('formQuestionsStringify')
  private watchFormQuestionsStringify(value: string, oldValue: string) {
    const current = JSON.parse(value)
    const old = JSON.parse(oldValue)
    if (!this.isRePassing && oldValue !== '[]')
      Sentry.addBreadcrumb({
        category: 'message',
        data: {
          formDiff: JSON.stringify(findObjectDifferences(old, current)),
          userID: this.selfID,
        },
        level: 'info',
        message: 'Форма изменена',
      })
  }
}
