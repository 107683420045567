































































































import { Component, Prop, Mixins } from 'vue-property-decorator'
import * as Sentry from '@sentry/vue'

import CardHint from '@/components/_uikit/CardHint.vue'
import MasterCodingTask from '@/components/views/exercise/master/MasterCodingTask.vue'
import MasterCreativeTask from '@/components/views/exercise/master/MasterCreativeTask.vue'
import MasterPracticeTask from '@/components/views/exercise/master/MasterPracticeTask.vue'
import MasterSpeakingTask from '@/components/views/exercise/master/MasterSpeakingTask.vue'
import MasterTestPassing from '@/components/views/exercise/master/MasterTestPassing.vue'
import Tag from '@/components/_uikit/Tag.vue'
import TestTries from '@/components/views/exercise/TestTries.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import MasterExercisesModule from '@/store/modules/master/exercises'
import {
  EducationLargeExerciseResource, EducationLargeTaskResource,
  EducationMasterGroupResource, ExerciseStatus, TaskType,
} from '@/store/types'
import { declension } from '@/utils/functions'
import TaskScore from '@/components/views/exercise/TaskScore.vue'
import MistakesModal from '@/components/modals/exercise/MistakesModal.vue'
import SystemModule from '@/store/modules/system'
import AuthModule from '@/store/modules/auth'
// import MasterProgressModule from '@/store/modules/master/progress'

@Component({
  components: {
    CardHint,
    MasterCodingTask,
    MasterCreativeTask,
    MasterPracticeTask,
    MasterSpeakingTask,
    MasterTestPassing,
    MistakesModal,
    Tag,
    TaskScore,
    TestTries,
  },
})
export default class ExercisesItemTaskAnswer extends Mixins(NotifyMixin) {
  @Prop({ required: true })
  private masterGroup!: EducationMasterGroupResource

  @Prop({ required: true })
  private exercise!: EducationLargeExerciseResource

  @Prop({ required: true })
  private task!: EducationLargeTaskResource

  private rePassing = false
  private visibleMistakesModal = false

  private get isCreative () {
    return this.task.type.value === TaskType.CREATIVE
  }

  private get isCoding() {
    return this.task.type.value === TaskType.CODING
  }

  private get isPractice () {
    return this.task.type.value === TaskType.PRACTICE
  }

  private get isTest () {
    return this.task.type.value === TaskType.TEST
  }

  private get isSpeaking() {
    return this.task.type.value === TaskType.SPEAKING
  }

  private get isStatusWait () {
    const { value } = this.task.status
    return value === ExerciseStatus.WAIT || value === ExerciseStatus.IN_PROCESS
  }

  private get isStatusChecked () {
    const { value } = this.task.status
    return value === ExerciseStatus.CHECKED
  }

  private get isStatusComplete () {
    const { value } = this.task.status
    return value === ExerciseStatus.COMPLETE
  }

  private get hintText () {
    if (this.isStatusChecked) {
      if (this.isPractice) {
        return this.task.isDeadlineFailed
          ? 'Задание отправлено на проверку наставнику и не подлежит редактированию.'
          : `Задание отправлено на проверку наставнику и не подлежит редактированию. Если ты допустил ошибку, ты&nbsp;можешь написать об этом сообщение до конца срока сдачи (${this.task.verificationHours} ${declension(this.task.verificationHours, ['час', 'часа', 'часов'])}). Сделать это&nbsp;можно во&nbsp;вкладке Сообщения.`
      }
    }

    return ''
  }

  private get taskUUID() {
    return this.$route.params.taskUUID
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private mounted() {
    this.$bus.$on('mistakesModal', this.mistakesModalOpen)
    this.$bus.$on('submitTest', this.handleSubmit)
  }

  private destroyed() {
    this.$bus.$off('mistakesModal', this.mistakesModalOpen as any)
    this.$bus.$off('submitTest', this.handleSubmit as any)
    // Отключаем режим РНО при уходе с текущего дз
    MasterExercisesModule.setEndMistakes()
    MasterExercisesModule.unsetCurrentMistake()
  }

  // private handleSubmit (isNeedCheck = false) {
  private handleSubmit () {
    this.fetchExercise()
    // if (isNeedCheck) this.checkUpdates(1)
  }

  private updateStatusTask(task: EducationLargeTaskResource) {
    MasterExercisesModule.setExerciseTask(task)
    if (task.exerciseStatus)
      MasterExercisesModule.setExercise({
        ...this.exercise,
        progress: {
          ...this.exercise.progress,
          status: task.exerciseStatus,
        },
      })
  }

  private fetchExercise() {
    MasterExercisesModule.fetchExercise({
      exerciseUUID: this.$route.params.exerciseUUID,
      masterGroupID: this.masterGroup.id,
    })
      .then(response => {
        this.rePassing = false
        if (response.progress.onCheckAt) {
          SystemModule.hideTimer()
          SystemModule.clearTimer()
        }
      })
      .catch((error: any) => {
        if (error.response.status === 404) {
          Sentry.addBreadcrumb({
            category: 'message',
            data: {
              exerciseUUID: this.$route.params.exerciseUUID,
              masterGroupID: this.masterGroup.id,
            },
            level: 'info',
            message: 'Данные по запросу',
          })
          Sentry.captureMessage('Расхождение ID курса ДЗ и ID курса МГ (Answer.vue)')
        }
        this.notifyError(error)
      })
  }

  private mistakesModalOpen() {
    this.visibleMistakesModal = true
  }

  // Запрос на поверку получения нового уровня
  // private checkUpdates (tick: number) {
  //   const timeout: number = tick === 1 ? 3000 : tick === 2 ? 4000 : 5000

  //   setTimeout(() => {
  //     MasterProgressModule.checkUpdates()
  //       .then((userUpd) => {
  //         if (userUpd.length) {
  //           this.$bus.$emit('new-level', userUpd[userUpd.length - 1])
  //         } else if (tick !== 3) {
  //           this.checkUpdates(tick + 1)
  //         }
  //       })
  //   }, timeout)
  // }
}
