















import { Component, Prop, Vue } from 'vue-property-decorator'

// store
import ProfileModule from '@/store/modules/profile'

@Component
export default class NewYearAvatarList extends Vue {
  @Prop({ required: true })
  private value!: number

  private get avatarList() {
    return ProfileModule.avatarList
  }
}
